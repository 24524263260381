import React, { ChangeEvent, useEffect, useState } from 'react'
import ViewMoreContainer from '../ViewMoreContainer'
import { CLAIMANT_DETAILS, COUNTER_PRAYER, EVIDENCES, RESPONDENT_DETAILS, RESPONDENT_PRAYER, RESPONDENT_STATEMENT_OF_OBJECTION, RESPONDENT_SYNOPSIS, SYNOPSIS } from '../../../../constants/case-state'
import { useDispatch, useSelector } from 'react-redux'
import StringDifferenceHighlighter from '../../../../components/StringDifferenceHighlighter'
import { CE, RD, SO, SY } from '../../../../constants/permission'
import { AppDispatch, RootState, store } from '../../../../redux/store'
import { isAcceptRejectEnabled, isViewAddRemarksEnabled, isViewEditEnabled } from '../../../../util/permission/role-permission'
import { formatDateTime } from '../../../../util/stringUtils'
import { RejectApproveBtns } from '../RejectApproveBtns'
import { Button, TextField, FormControlLabel, Checkbox, Box, Tooltip } from '@mui/material'
import { Evidence } from '../../../../model/caseDocument/synopsis'
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MediaLayout from '../../../../Layout/MediaLayout'
import ViewMedia from '../../../../components/ViewMedia'
import { NEW_CASE, DRAFT, SCRUTINY_REVIEW, RM_CONCILIATION, RM_MEDIATION, SENT_FOR_RESCRUTINY } from '../../../../constants/case-constants'
import FileService from '../../../../network/FileService'
import { DASHBOARD } from '../../../../routes/routes'
import FileUpload from '../../../../components/common/FileUpload'
import { useNavigate } from 'react-router-dom'
import { caseResponseThunk, editResponseSynopsisEvidence, updateSynopsis } from '../../../../redux/slice/caseResponseSlice'
import { reUploadSynopsisEvidence } from '../../../../redux/slice/caseResponseSlice'
import { ShowLabel } from '../../../../components/common/ShowLabel'
import { StatusType } from '../../../../model/fileCase'
import { DateOption } from '../../../../redux/slice/components/initCaseSliceState'
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import HTMLDiffComponent from '../../../../components/HTMLDiffComponent'


function ViewCaseResponseContent({ showLoadBtn, showHighlighter = false, showPreVersion = false ,version,selectedVersion}: {
  showLoadBtn: boolean,
  showHighlighter?: boolean,
  showPreVersion?: boolean,
  isVersionPage?: boolean
  version?: string,
  selectedVersion?: string,
}) {

  const [loadSynopsisStatus, setLoadSynopsisStatus] = useState(!showLoadBtn)
  const [loadStatementOfObjection, setStatementOfObjection] = useState(!showLoadBtn)
  const [loadCounterPrayersStatus, setLoadCounterPrayersStatus] = useState(!showLoadBtn)
  const [loadEvidencesStatus, setLoadEvidencesStatus] = useState(!showLoadBtn)
  const [loadClaimantStatus, setLoadClaimantStatus] = useState(!showLoadBtn);
  const [loadRespondentStatus, setLoadRespondentStatus] = useState(
    !showLoadBtn
  );
  const [loadPrayersStatus, setLoadPrayersStatus] = useState(!showLoadBtn);


  const [openFile, setOpenFile] = useState(false);
  const [isFilePdf, setIsFilePdf] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [fileToOpen, setFileToOpen] = useState<Evidence | null>(null);
  const [isUploading, setUploading] = useState<boolean>(false);



  const [activeEvidenceIndex, setActiveEvidenceIndex] = useState<
    number | null
  >();
  const [activeSynopsisIndex, setActiveSynopsisIndex] = useState<
    number | null
  >();

  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();


  // const { caseFileResponse, caseFileResponseDetail,permissionState } = useSelector((state: RootState) => state.caseResponse);
  const { caseFileResponse, caseFileResponseDetail, permissionState } = store.getState().caseResponse
  const { claimantList, respondentList, caseFile, scrutinyCount, isAdmin,selectedResponseId } = useSelector((state: RootState) => state.case);


  useEffect(()=>{
    if(version){
      if(selectedVersion){
        dispatch(caseResponseThunk({ uniqueId: selectedResponseId as string,
          queryParams:{version: version,preVersion: selectedVersion}
         }))
      }else{
        dispatch(caseResponseThunk({ uniqueId: selectedResponseId as string,
          queryParams:{version: version}
         }))
      }
      

       
    }

  },[])



  const addFile = async (
    file: File,
    synopsisIndex: number,
    evidenceIndex: number
  ) => {
    try {
      if (caseFile.uniqueId) {
        if (!file) {
          throw Error("File list is null");

        }

        const args: any = [
          caseFile.status === NEW_CASE ? DRAFT : caseFile.status,
          caseFile.uniqueId,
        ];

        const { actualFileName, uploadedFileName, mineType } =
          (await FileService.caseFileUpload(file, args)) as any;

        dispatch(
          reUploadSynopsisEvidence({
            removeFile: false,
            synopsisIndex,
            evidenceIndex,
            actualFileName: actualFileName,
            uploadedFileName: uploadedFileName,
            tempReUpload: true,
            mineType: mineType,
          })
        );

        dispatch(updateSynopsis({ synopsisIndex, key: "evidences" }));

      } else {

        navigate(DASHBOARD);
      }

    } catch (error) {

      console.error(error);
    } finally {
      setUploading(false)

    }
  };




  const handelEvidenceChange = (
    key: string,
    value: any,
    synopsysIndex: number,
    evidenceIndex: number
  ) => {

    dispatch(
      editResponseSynopsisEvidence({
        synopsysIndex,
        evidenceIndex,
        key,
        value,
      })
    );

  };


  const handleFileInputChange = async (
    e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
    extra: any
  ) => {
    e.preventDefault();

    const { synopsisIndex, evidenceIndex } = extra;
    setUploading(true);

    let fileList: FileList | null;

    if ("dataTransfer" in e) {
      fileList = e.dataTransfer.files;
    } else {
      fileList = e.target.files;
    }

    if (fileList && fileList.length > 0) {
      addFile(fileList[0], synopsisIndex, evidenceIndex);
    } else {
      setUploading(false);
      throw new Error("File list is null");
    }
  };

  const removeFile = (key: any, data: any) => {
    const { synopsisIndex, evidenceIndex } = data;
    dispatch(
      reUploadSynopsisEvidence({
        removeFile: true,
        synopsisIndex,
        evidenceIndex,
        actualFileName: "",
        uploadedFileName: "",
        tempReUpload: false,
      })
    );
  };



  const getIntensity = (scale: number) => {
    if (scale >= 0 && scale <= 3) {
      return "Low";
    } else if (scale >= 4 && scale <= 7) {
      return "Medium";
    } else if (scale >= 8 && scale <= 10) {
      return "High";
    } else {
      return "";
    }
  };


  const handleViewFile = async (evidence: Evidence) => {
    const url = [
      caseFile.status,
      caseFile.uniqueId ?? "",
      "SYNOPSIS",
      evidence.uploadedFileName,
    ].join("/");

    setIsFilePdf(url.endsWith(".pdf"));
    setOpenFile(true)
    setFileUrl(url)
    setFileToOpen(evidence)
  };

  return (
    <div className="d-flex gap-5 flex-column py-5 px-4 h-100 overflow-auto">
      <div className="d-flex flex-column gap-1">
        <h4 className="fs-sm-lg-semibold text-center p-0 m-0">
        {caseFile.resolutionMethod===RM_MEDIATION?"MEDIATION PROCEEDINGS"
            :caseFile.resolutionMethod===RM_CONCILIATION?"CONCILIATION PROCEEDINGS":"Before the Hon'ble Arbitral Tribunal"}
        </h4>
        {/* <h4 className="fs-sm-lg-semibold text-center p-0 m-0">
            WRIT PETITION NO. / 2023 (GM-MM-S)
          </h4> */}
      </div>
      <ViewMoreContainer
        sectionId={CLAIMANT_DETAILS}
        label={caseFile.resolutionMethod === RM_CONCILIATION || caseFile.resolutionMethod === RM_MEDIATION ? "First Party Details" : "Claimant Details"}

        showLoadBtn={showLoadBtn}
        loadBtnStatus={loadClaimantStatus}
        setLoadBtnStatus={setLoadClaimantStatus}
        scrutinyCount={null}

      >
        {loadClaimantStatus ? (
          <div className="px-3 py-4">
            <div className="row row-cols-2 g-5">
              {claimantList?.map((data, key) => (
                <div className="col" key={key}>
                  <div className="d-flex gap-2 align-items-center">
                    <span className="fs-normal-light p-0 m-0">
                      {key + 1}.
                    </span>
                    <span className="fs-normal-med p-0 m-0">

                      <>{data.name}</>

                    </span>
                  </div>
                  <div className="fs-normal-light p-0 ms-3">

                    <>{data.orgRepresentative}</>

                  </div>
                  <div className="fs-normal-light p-0 ms-3">

                    <>{data.designation}</>

                  </div>
                  <div className="fs-normal-light p-0 ms-3">

                    <>{data.address}</>

                  </div>
                  <div className="fs-normal-light p-0 ms-3">

                    <>{data.mobileNo}</>

                  </div>
                  <Tooltip title={data?.email??""}>

<div className="fs-normal-light p-0 ms-3" style={{
  display: 'inline-block',
  maxWidth: '200px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  verticalAlign: 'middle',
}}>

                    <>{data.email}</>

                  </div>
                  </Tooltip>


                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="pb-4">
            {claimantList?.map((data, key) => (
              <div
                className={`d-flex gap-5 align-items-center ${key % 2 !== 0 && "bg-light-blue2"
                  } px-3 py-2`}
                key={key}
              >
                <div className="d-flex gap-2 align-items-center">
                  <span className="fs-normal-light p-0 m-0">{key + 1}.</span>
                  <span className="fs-normal-light p-0 m-0">{data.name}</span>
                </div>
                <div className="fs-normal-light flex-grow-1 p-0 m-0">
                  {data.address}
                </div>
              </div>
            ))}
          </div>
        )}
      </ViewMoreContainer>

      <ViewMoreContainer
        sectionId={RESPONDENT_DETAILS}
        label={caseFile.resolutionMethod === RM_CONCILIATION || caseFile.resolutionMethod === RM_MEDIATION ? "Second Party Details" : "Respondent Details"}

        showLoadBtn={showLoadBtn}
        loadBtnStatus={loadRespondentStatus}
        setLoadBtnStatus={setLoadRespondentStatus}
        scrutinyCount={null}



      >
        {loadRespondentStatus ? (
          <div className="px-3 py-4">
            <div className="row row-cols-2 g-5">
              {respondentList?.map((data, key) => (
                <div className="col" key={key}>
                  <div className="d-flex gap-2 align-items-center">
                    <span className="fs-normal-light p-0 m-0">
                      {key + 1}.
                    </span>
                    <span className="fs-normal-med p-0 m-0">

                      <>{data.name}</>

                    </span>
                  </div>
                  
                  <div className="fs-normal-light p-0 ms-3">

                    <>{data.orgRepresentative}</>

                  </div>

                  <div className="fs-normal-light p-0 ms-3">

                    <>{data.designation}</>

                  </div>


                  <div className="fs-normal-light p-0 ms-3">

                    <>{data.address}</>

                  </div>
                  <div className="fs-normal-light p-0 ms-3">

                    <>{data.mobileNo}</>

                  </div>
                  <Tooltip title={data?.email??""}>

                                  <div className="fs-normal-light p-0 ms-3" style={{
                                    display: 'inline-block',
                                    maxWidth: '200px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    verticalAlign: 'middle',
                                    
                                  }}>

                    <>{data.email}</>

                  </div>
                  </Tooltip>

                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="pb-4">
            {respondentList?.map((data, key) => (
              <div
                className={`d-flex gap-5 align-items-center ${key % 2 !== 0 && "bg-light-blue2"
                  } px-3 py-2`}
                key={key}
              >
                <div className="d-flex gap-2 align-items-center">
                  <span className="fs-normal-light p-0 m-0">{key + 1}.</span>
                  <span className="fs-normal-light p-0 m-0">{data.name}</span>
                </div>
               
                <div className="fs-normal-light flex-grow-1 p-0 m-0">
                  {data.address}
                </div>
              </div>
            ))}
          </div>
        )}
      </ViewMoreContainer>


      <ViewMoreContainer
        sectionId={RESPONDENT_SYNOPSIS}
        label={"Synopsis"}
        showLoadBtn={showLoadBtn}
        loadBtnStatus={loadSynopsisStatus}
        setLoadBtnStatus={setLoadSynopsisStatus}
        isRespondentData={true}
        scrutinyCount={caseFileResponse.status === SCRUTINY_REVIEW ? caseFileResponseDetail?.responseScrutinyCount?.synopsis : null}
        enableEdit={isViewEditEnabled(SY, permissionState)}



      >
        {loadSynopsisStatus ? (
          <div className="px-3 py-4">
            <div className="d-flex align-items-center h-100">
              <div
                className="flex-shrink-0 p-2"
                style={{ border: "0.5px solid #E5E5FF", flexBasis: "20%" }}
              >
                <p className="fs-normal-med text-center p-0 m-0">Date </p>
              </div>
              <div
                className="flex-grow-1 p-2"
                style={{ border: "0.5px solid #E5E5FF" }}
              >
                <p className="fs-normal-med text-center p-0 m-0">Events</p>
              </div>
              <div
                className="flex-shrink-0 p-2"
                style={{ border: "0.5px solid #E5E5FF", flexBasis: "20%" }}
              >
                <p className="fs-normal-med text-center p-0 m-0">Evidences</p>
              </div>
            </div>

            <div className="d-flex gap-3 flex-column">
              {caseFileResponseDetail.synopsis?.map((data, key) => (
                <div className="d-flex h-100 flex-column" key={key}>
                  <div className="d-flex">
                    <div
                      className="flex-shrink-0 d-flex justify-content-center align-items-center p-2"
                      style={{
                        border: "0.5px solid #E5E5FF",
                        flexBasis: "20%",
                      }}
                    >
                      {/* <p className="fs-normal-light text-center p-0 m-0">
                          {data.dateTime}
                        </p> */}
                      <p className="fs-normal-light text-center p-0 m-0">
                        {/* {formatDateTime(data.dates[0]??"", false)} */}
                        {((caseFileResponse.status === SCRUTINY_REVIEW &&
                          data?.hasChanges &&
                          !isAdmin) || showHighlighter) ? (
                            <HTMLDiffComponent oldContent=
                            {caseFileResponseDetail?.previousSynopsis?caseFileResponseDetail?.previousSynopsis[key]?.dateType === DateOption.NOT_APPLICABLE
                              ? "N/A"
                              : caseFileResponseDetail?.previousSynopsis[key]?.dates?.length
                                ? caseFileResponseDetail?.previousSynopsis[key].dates
                                  ?.map((date:any) =>
                                    caseFileResponseDetail?.previousSynopsis[key]?.dateType !== DateOption.ONLY_YEAR ? (
                                      formatDateTime(date, false)
                                    ) : (
                                      date
                                    )
                                
                                  )
                                  .join(caseFileResponseDetail?.previousSynopsis[key].dateType === DateOption.RANGE_OF_DATES ? " - " : " , ")
                                : '':""} newContent={data.dateType === DateOption.NOT_APPLICABLE
                                  ? "N/A"
                                  : data?.dates?.length
                                    ? data?.dates
                                      ?.map((date:any) =>
                                        data?.dateType !== DateOption.ONLY_YEAR ? (
                                          formatDateTime(date, false)
                                        ) : (
                                          date
                                        )
                                    
                                      )
                                      .join(data?.dateType === DateOption.RANGE_OF_DATES ? " - " : " , ")
                                    : ''}
                                     />
                                ):data?.dates?.length
                                ? data?.dates
                                  ?.map((date:any) =>
                                    data?.dateType !== DateOption.ONLY_YEAR ? (
                                      formatDateTime(date, false)
                                    ) : (
                                      date
                                    )
                                
                                  )
                                  .join(data?.dateType === DateOption.RANGE_OF_DATES ? " - " : " , ")
                                : ''}
                       
    
                      

                      </p>
                    </div>

                    <div
                      className="flex-grow-1 p-2"
                      style={{ border: "0.5px solid #E5E5FF" }}
                    >
                      <div className="fs-normal-light text-start p-0 m-0">
                        {((caseFileResponse.status === SCRUTINY_REVIEW &&
                          data?.hasChanges &&
                          !isAdmin) || showHighlighter) ? (
                          <>
                            <StringDifferenceHighlighter
                              original={
                                caseFileResponseDetail?.previousSynopsis[key]
                                  ?.eventDetail as string??""
                              }
                              modified={data?.eventDetail??""}
                            />
                          </>
                        ) : (
                          <>{data?.eventDetail}</>
                        )}
                      </div>
                      {isAcceptRejectEnabled(SY, permissionState) &&
                        data.hasChanges && (
                          <>
                            <RejectApproveBtns
                              dataKey={SYNOPSIS}
                              index={key}
                            />
                          </>
                        )}
                    </div>
                    <div
                      className="flex-shrink-0 d-flex justify-content-center align-items-center flex-column p-2"
                      style={{
                        border: "0.5px solid #E5E5FF",
                        flexBasis: "20%",
                      }}
                    >
                      <p className="fs-normal-light text-center p-0 m-0">
                        DR
                      </p>
                      <div className="d-flex gap-2 align-items-center flex-wrap">
                        {
                          data.evidences?.map((evidence, evidenceIndex) => (
                            <p className="fs-normal-light p-0 m-0 custom-file-link" onClick={() => handleViewFile(evidence)} key={evidenceIndex}>
                              <AttachFileIcon fontSize="small" />
                              {evidence.actualFileName}
                            </p>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="pb-4">
            <div className="d-flex gap-3 align-items-center px-3 py-2">
              <div
                className="fs-normal-med text-start flex-shrink-0 p-0 m-0"
                style={{ flexBasis: "10%" }}
              >
                Date
              </div>
              <div className="fs-normal-med text-center flex-grow-1 p-0 m-0">
                Events
              </div>
              <div
                className="fs-normal-med text-end flex-shrink-0 p-0 m-0"
                style={{ flexBasis: "10%" }}
              >
                Evidences
              </div>
            </div>
            {caseFileResponseDetail?.synopsis?.map((data, key) => (
              <div
                className={`d-flex gap-3 align-items-center ${key % 2 === 0 && "bg-light-blue2"
                  } px-3 py-2`}
                key={key}
              >
                <div
                  className="fs-normal-light text-start flex-shrink-0 p-0 m-0"
                  style={{ flexBasis: "10%" }}
                >
                  {/* {formatDateTime(data.dates[0]??"",false)}
                   */}

                  {data.dateType === DateOption.NOT_APPLICABLE
                    ? "N/A"
                    : data?.dates?.length
                      ? data.dates
                        .map((date) =>
                          data.dateType !== DateOption.ONLY_YEAR ? (
                            formatDateTime(date, false)
                          ) : (
                            date
                          )
                        )
                        .join(data.dateType === DateOption.RANGE_OF_DATES ? " - " : " , ")
                      : null}


                </div>
                <div
                  className="fs-normal-light text-start flex-grow-1 p-0 m-0"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: 0,
                  }}
                >
                  {data.eventDetail}
                </div>
                <div
                  className="fs-normal-light text-end flex-shrink-0 p-0 m-0"
                  style={{ flexBasis: "10%" }}
                >
                  DR :{data.evidences.length}

                </div>
              </div>
            ))}
          </div>
        )}
      </ViewMoreContainer>


      <ViewMoreContainer
        sectionId={RESPONDENT_STATEMENT_OF_OBJECTION}
        label="Statement of Defence"
        showLoadBtn={showLoadBtn}
        loadBtnStatus={loadStatementOfObjection}
        setLoadBtnStatus={setStatementOfObjection}
        isRespondentData={true}
        scrutinyCount={caseFileResponse.status === SCRUTINY_REVIEW ? caseFileResponseDetail?.responseScrutinyCount?.statementOfObjection : null}
        enableEdit={isViewEditEnabled(SO, permissionState)}

      >
        {loadStatementOfObjection ? (
          <div className="p-2">
            {((caseFileResponse.status === SCRUTINY_REVIEW &&
              (caseFileResponseDetail?.statementOfObjection?.hasChanges) &&
              !isAdmin) || showHighlighter) ? (
              <HTMLDiffComponent oldContent={caseFileResponseDetail?.previousStatementOfObjection?.statement ?? ''} newContent={caseFileResponseDetail?.statementOfObjection?.statement ?? ''} />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: caseFileResponseDetail?.statementOfObjection?.statement,
                }}
              ></div>
            )}

            <div className="p-2 mb-3">
              {isAcceptRejectEnabled(SO, permissionState) &&
                caseFileResponseDetail?.statementOfObjection?.hasChanges && (
                  <>
                    <RejectApproveBtns
                      dataKey={RESPONDENT_STATEMENT_OF_OBJECTION}
                      index={0}
                    />
                  </>
              )}
            </div>
        </div>
        ) : (
          <>

            <div className="pb-4">
              <div className="d-flex align-items-center px-3 py-2">
                <div className="fs-normal-light  fst-italic  p-0 m-0">
                  Preview is not available click on "view more" to view
                  complete statement Of defence.
                </div>
              </div>
            </div>
          </>
        )}
      </ViewMoreContainer>

      <ViewMoreContainer
        sectionId={RESPONDENT_PRAYER}
        label="Response to Claim"
        showLoadBtn={showLoadBtn}
        loadBtnStatus={loadPrayersStatus}
        setLoadBtnStatus={setLoadPrayersStatus}
        scrutinyCount={null}


      >
        {loadPrayersStatus ? (
          <div className="px-3 py-4">
            {/* <h4 className="fs-sm-lg-semibold mb-3">Prayers</h4> */}
            <div className="row row-cols-2 g-5">
              {caseFileResponseDetail.prayer?.prayers?.map((data: any, key: any) => (
                <>
                
                {
                  data.status?(
                      <div className="col h-100" key={key}>
                        <div className="d-flex gap-2">
                          <span className="fs-normal-light p-0 m-0">
                            {key + 1}.
                          </span>
                          <div className="d-flex gap-2 flex-column">
                            <span
                              className={`fs-normal-light  "text-grey" 
                            }`}
                            >
                              {data?.prayerDetail}

                            </span>

                            <span
                              className={` fs-normal-light text-grey
                            }`}
                            >
                              Intensity of wants: {getIntensity(data.intensity)}
                            </span>
                            <div className="d-flex gap-2">
                              <ShowLabel label={data.status === 1 ? "Agreed" : data.status === 2 ? "Rejected" : data.status === 3 ? "Partial" : null}
                                status={data.status === 1 ? StatusType.SUCCESS : data.status === 2 ? StatusType.DANGER : data.status === 3 ? StatusType.WARNING : null}
                              />

                              {data.objection &&
                                (
                                  <span className="  fs-normal-light text-grey">
                                    :  {data.objection}
                                  </span>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                  ):null
                }
                </>
                
              ))}
            </div>
          </div>
        ) : (
          <div className="pb-4">
            {caseFileResponseDetail.prayer?.prayers?.map((data, key: number) => (
              <>
              
                {
                    data.status ? (<div
                      className={`d-flex gap-3 align-items-center ${key % 2 !== 0 && "bg-light-blue2"
                        } px-3 py-2`}
                      key={key}
                    >
                      <div
                        className="fs-normal-light text-start flex-shrink-0 p-0 m-0"
                        style={{ flexBasis: "5%" }}
                      >
                        {key + 1}
                      </div>
                      <div
                        className="d-flex gap-2 flex-column fs-normal-light text-start flex-grow-1 p-0 m-0"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: 0,
                        }}
                      >
                        <span className="fs-normal-light">{data?.prayerDetail}
                        </span>
                      </div>
                      <div
                        className="d-flex gap-2 flex-column fs-normal-med text-end flex-shrink-0 p-0 m-0"
                        style={{ flexBasis: "25%" }}
                      >
                        <span
                          className={`fs-normal-light text-grey
                        }`}
                        >
                          Intensity of wants: {getIntensity(data.intensity)}
                        </span>
                      </div>
                    </div>
                  ):null
              }
              </>
             
            ))}
          </div>
        )}
      </ViewMoreContainer>


      {caseFileResponseDetail?.prayer?.counterPrayer.length&&caseFileResponseDetail?.prayer?.counterPrayer[0]?.prayerDetail ?
        (
          <ViewMoreContainer
            sectionId={COUNTER_PRAYER}
            label="Counter Prayers"
            showLoadBtn={showLoadBtn}
            loadBtnStatus={loadCounterPrayersStatus}
            setLoadBtnStatus={setLoadCounterPrayersStatus}
            isRespondentData={true}
            scrutinyCount={null}

          >
            {loadCounterPrayersStatus ? (
              <div className="px-3 py-4">
                {/* <h4 className="fs-sm-lg-semibold mb-3">Counter Prayer</h4> */}
                <div className="row row-cols-2 g-5">
                  {caseFileResponseDetail?.prayer?.counterPrayer?.map(
                    (data, key) => (
                      <div className="col h-100" key={key}>
                        <div className="d-flex gap-2">
                          <span className="fs-normal-light p-0 m-0">
                            {key + 1}.
                          </span>
                          <div className="d-flex gap-2 flex-column">
                            <span
                              className={` text-grey
                                }`}
                            >
                              {data?.prayerDetail}
                            </span>

                            <span
                              className={` text-grey
                                }`}
                            >
                              Intensity of wants: {getIntensity(data.intensity)}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            ) : (
              <div className="pb-4">
                {caseFileResponseDetail?.prayer?.counterPrayer?.map(
                  (data, key: number) => (
                    <div
                      className={`d-flex gap-3 align-items-center ${key % 2 !== 0 && "bg-light-blue2"
                        } px-3 py-2`}
                      key={key}
                    >
                      <div
                        className="fs-normal-light text-start flex-shrink-0 p-0 m-0"
                        style={{ flexBasis: "5%" }}
                      >
                        {key + 1}
                      </div>
                      <div
                        className="d-flex gap-2 flex-column fs-normal-light text-start flex-grow-1 p-0 m-0"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: 0,
                        }}
                      >
                        <span className="">{data?.prayerDetail}</span>
                      </div>
                      <div
                        className="d-flex gap-2 flex-column fs-normal-med text-end flex-shrink-0 p-0 m-0"
                        style={{ flexBasis: "25%" }}
                      >
                        <span
                          className={` "text-grey" `}
                        >
                          Intensity of wants: {getIntensity(data.intensity)}
                        </span>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </ViewMoreContainer>

        )
        : null}

      <ViewMoreContainer
        sectionId={EVIDENCES}
        label="Evidences"
        showLoadBtn={showLoadBtn}
        loadBtnStatus={loadEvidencesStatus}
        setLoadBtnStatus={setLoadEvidencesStatus}
        scrutinyCount={caseFileResponseDetail.responseScrutinyCount?.evidence ?? null}


      >
        {loadEvidencesStatus ? (
          <>
            {caseFileResponseDetail?.synopsis?.map((data, synopsisIndex) => (
              <div className="mb-3 p-2" key={synopsisIndex}>
                <div className="fs-normal-med col-2 p-2">
                <div className="fs-normal-light text-start "
                    style={{ flexBasis: "10%" }}>
                    {/* {formatDateTime(data.dates[0]??"", false)}
                     */}

                    {data.dateType === DateOption.NOT_APPLICABLE
                      ? "N/A"
                      : data?.dates?.length
                        ? data.dates
                          ?.map((date) =>
                            data.dateType !== DateOption.ONLY_YEAR ? (
                              formatDateTime(date, false)
                            ) : (
                              date
                            )
                          )
                          .join(data.dateType === DateOption.RANGE_OF_DATES ? " - " : " , ")
                        : null}
                  </div>
                </div>

                {data.evidences?.map((evidence, evidenceIndex) => (
                  <div key={evidenceIndex}>
                    <span className={`fs-normal-light `}>
                      <AttachFileIcon fontSize="small" />

                      <span
                        className={`${evidence.tempReUpload ? "text-success" : ""
                          } cursor-pointer`}
                        onClick={() => {
                          handleViewFile(evidence)
                        }}
                      >
                        {evidence.actualFileName
                          ? evidence.actualFileName
                          : "- - -"}
                      </span>


                      {
                        evidence.reUpload === 1 && caseFile.status === SENT_FOR_RESCRUTINY ? (
                          <Tooltip title="Re-uploaded file !">
                            <CloudSyncIcon color="error" fontSize="small" />

                          </Tooltip>
                        ) : null
                      }

                      {evidence.tempReUpload && (
                        <CheckCircleIcon
                          className="ms-2 text-success "
                          fontSize="small"
                        />
                      )}
                    </span>
                    <div className="text-grey ms-4">
                      {evidence.description}
                    </div>

                    {isAcceptRejectEnabled(CE, permissionState) &&
                      evidence.reUpload === 1 && (
                        <>
                          <div className="px-3 my-3">
                            <div className={`w-100 rounded p-2 bg-light-red`}>
                              <div className={`fs-normal-semibold `}>
                                Remarks By TruPact
                              </div>
                              {evidence.reUpload === 1 && (
                                <div className="fs-normal-med">
                                  {evidence?.remark}
                                </div>
                              )}
                            </div>

                            <div className="w-75 mx-auto">
                              <div className="mt-2">Reupload evidences</div>

                              <FileUpload
                                name={evidence.uploadedFileName}
                                fileName={
                                  evidence.tempReUpload
                                    ? evidence.actualFileName
                                    : ""
                                }
                                addFile={handleFileInputChange}
                                fileInfo={{
                                  synopsisIndex: synopsisIndex,
                                  evidenceIndex: evidenceIndex,
                                }}
                                removeFile={removeFile}
                                evidence={evidence}
                                loading={isUploading}
                                url={
                                  [
                                    caseFile.status === NEW_CASE ? DRAFT : caseFile.status,
                                    caseFile.uniqueId ?? "",
                                    "SYNOPSIS",
                                    evidence.uploadedFileName,
                                  ].join("/")
                                }
                              />
                            </div>
                          </div>
                        </>
                      )}

                    {(activeEvidenceIndex !== evidenceIndex ||
                      activeSynopsisIndex !== synopsisIndex) &&
                      isViewAddRemarksEnabled(CE, permissionState) &&
                      isAdmin && (
                        <>
                          <div className="text-center mb-2 ">
                            <Button
                              className="rounded-2"
                              variant="text"
                              sx={{ textTransform: "none" }}
                              onClick={() => {
                                setActiveEvidenceIndex(evidenceIndex);
                                setActiveSynopsisIndex(synopsisIndex);
                              }}
                            >
                              Add Remark
                            </Button>
                          </div>

                        </>
                      )}

                    {activeEvidenceIndex === evidenceIndex &&
                      activeSynopsisIndex === synopsisIndex &&
                      isAdmin && (
                        <div className="p-4">
                          <TextField
                            className="w-100"
                            name="remark"
                            label="Add remark"
                            placeholder="Add your remarks"
                            multiline
                            value={evidence.remark}
                            onChange={(event) => {
                              handelEvidenceChange(
                                event.target.name,
                                event.target.value,
                                synopsisIndex,
                                evidenceIndex
                              );
                            }}
                            rows={3}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="reUpload"
                                checked={
                                  evidence.reUpload === 1 ? true : false
                                }
                                onChange={(event) => {
                                  handelEvidenceChange(
                                    event.target.name,
                                    event.target.checked ? 1 : 0,
                                    synopsisIndex,
                                    evidenceIndex
                                  );
                                }}
                              />
                            }
                            label="Reupload attachment"
                          />
                        </div>

                      )}
                  </div>
                ))}
              </div>
            ))}
          </>
        ) : (
          <>
            {caseFileResponseDetail?.synopsis?.map((data, i) => (
              <div key={i} className="m-1 row w-100 mb-4 ">
                {/* <div className="fs-normal-med col-2">{data.dateTime}</div> */}
                <div className="fs-normal-med col-2">

                <div className="fs-normal-light text-start "
                    style={{ flexBasis: "10%" }}>

                    {data.dateType === DateOption.NOT_APPLICABLE
                      ? "N/A"
                      : data?.dates?.length
                        ? data.dates
                          ?.map((date) =>
                            data.dateType !== DateOption.ONLY_YEAR ? (
                              formatDateTime(date, false)
                            ) : (
                              date
                            )
                          )
                          .join(data.dateType === DateOption.RANGE_OF_DATES ? " - " : " , ")
                        : null} 
                  </div>

                </div>
                <Box
                  className="fs-normal-med col-8 text-grey text-truncate   "
                  maxWidth={800}
                >
                  {data.eventDetail}
                </Box>
                <div className="fs-normal-med col-2 text-center">
                  DR : {data.evidences.length}
                </div>
              </div>
            ))}
          </>
        )}
      </ViewMoreContainer>

      <MediaLayout
        title={fileToOpen?.actualFileName ?? "Media Preview"}
        open={openFile}
        handleClose={() => {
          setOpenFile(false)
        }}
      >
        <ViewMedia isPdf={isFilePdf} url={fileUrl ?? ""} />
      </MediaLayout>
    </div>
  )
}

export default ViewCaseResponseContent