import React, { useRef, useState } from "react";
import { RootState } from "../../redux/store";
import { Evidence } from "../../model/caseDocument/synopsis";
import { useSelector } from "react-redux";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Doc22Icon from "../../assets/icon/doc_22x.svg";
import FileService from "../../network/FileService";
import { DRAFT, NEW_CASE } from "../../constants/case-constants";
import { FileUploadApiType } from "../../constants/api-constants";
import { AgreementDocument } from "../../model/fileCase";
import { CaseAgreement } from "../../redux/slice/components/initCaseSliceState";
import { Close } from "@mui/icons-material";
import MediaLayout from "../../Layout/MediaLayout";
import ViewMedia from "../ViewMedia";
import { useSnackbar } from "../../context/SnackbarContext";

interface FileUploadLayoutProps {
    url?: string;
    isLoading?: boolean;
    fileType?: string;
    fileToView: Evidence | AgreementDocument | CaseAgreement | null;
    addFile?: (file: Evidence | AgreementDocument | CaseAgreement) => void;
    removeFile?: (() => void) | null;
    fileUploadApiType?: FileUploadApiType;
    errorMessage?: string;
}
function FileUploadLayout({
    url,
    fileToView,
    isLoading,
    fileType,
    addFile,
    removeFile,
    fileUploadApiType,
    errorMessage,
}: FileUploadLayoutProps) {
    const { showSnackbar } = useSnackbar();

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isFileLoading, setIsFileLoading] = useState(false);

    const [openFile, setOpenFile] = useState(false);
    const [isFilePdf, setIsFilePdf] = useState(false);
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [fileToOpen, setFileToOpen] = useState<
        Evidence | AgreementDocument | null
    >(null);
    const [isDraggingOver, setIsDraggingOver] = useState(false);

    const { caseFile } = useSelector((state: RootState) => state.case);

    const validateFileType = (file: File) => {
        const allowedTypes = fileType
            ? fileType.split(",")
            : [".pdf", ".jpg", ".png"];
        return allowedTypes.some((type) => file.name.endsWith(type.trim()));
    };

    const handleViewFile = async (evidence: Evidence | AgreementDocument) => {
        const url = [
            caseFile.status === NEW_CASE ? DRAFT : caseFile.status,
            caseFile.uniqueId ?? "",
            "SYNOPSIS",
            evidence.uploadedFileName,
        ].join("/");

        setIsFilePdf(url.endsWith(".pdf"));
        setOpenFile(true);
        setFileUrl(url);
        setFileToOpen(evidence);
    };

    const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault(); // Prevent default action
        setIsDraggingOver(false);

        if (fileInputRef?.current) {
            fileInputRef.current.value = "";
        }
        if (validateFileType(e.dataTransfer.files[0])) {
            handleAddFile(e.dataTransfer.files[0]);
        } else {
            showSnackbar("Invalid file type: " + e.dataTransfer.files[0].type);
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDraggingOver(true);
        e.dataTransfer.dropEffect = "copy";
    };

    const handleDragLeave = () => {
        setIsDraggingOver(false);
    };

    const uploadCaseFile = async (file: File) => {
        try {
            if (!file) {
                throw Error("File list is null");
            }

            let args: any = [];
            if (caseFile?.uniqueId) {
                args = [
                    caseFile.status === NEW_CASE ? DRAFT : caseFile.status,
                    caseFile.uniqueId,
                ];
            }

            const response = (await FileService.caseFileUpload(file, args)) as any;

            return response;
        } catch (error) {
            console.error(error);
        }
    };

    const handleAddFile = async (file: any) => {
        setIsFileLoading(true);

        if (file) {
            let response = null as any;

            switch (fileUploadApiType) {
                case FileUploadApiType.CASE:
                    response = await uploadCaseFile(file);
                    break;
                case FileUploadApiType.KYC:
                    // response = await uploadCaseFile(file);
                    alert("implementation is needed");
                    break;
                case FileUploadApiType.USER:
                    // response = await uploadCaseFile(file);
                    alert("implementation is needed");

                    break;
                default:
                    break;
            }

            // pass the response file to function
            if (addFile) {
                addFile(response as Evidence);
            }
        }

        setIsFileLoading(false);
    };

    // const handleRemoveFile = () => {
    //     if (removeFile) removeFile(name, { ...fileInfo, key: name });
    // };
    const [openDocDialog, setOpenDocDialog] = useState(false);
    const [docInfo, setDocInfo] = useState<any | null>(null);

    const toggleDocDialog = (fileInfo: any = {}) => {
        if (openDocDialog) {
            setDocInfo(null);
            setOpenDocDialog(false);
        } else {
            setDocInfo(fileInfo);
            setOpenDocDialog(true);
        }
    };

    return (
        <>
            <input
                type={"file"}
                accept={fileType ?? ".pdf,.jpg,.png"} // Specify accepted file types if needed
                onChange={(e: any) => {
                    if (e.target?.files) {
                        if (validateFileType(e.target.files[0])) {
                            handleAddFile(e.target.files[0]);
                        } else {
                            showSnackbar("Invalid file type: " + e.target.files[0].type);
                        }

                        if (fileInputRef?.current) {
                            fileInputRef.current.value = "";
                        }
                    }
                }}
                style={{ display: "none" }}
                ref={fileInputRef}
            />

            {!fileToView ? (
                <div
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onClick={() => fileInputRef.current?.click()}
                >
                    <div className={`drop-area ${isDraggingOver ? "drag-over" : ""}`}>
                        {!isFileLoading ? (
                            <>
                                <CloudUploadIcon sx={{ color: "grey", fontSize: "28px" }} />
                                <span className="fs-sm-lg-med text-grey">
                                    Browse or Drag & drop
                                </span>
                            </>
                        ) : (
                            <>
                                <span className="fst-italic fw-bold">Loading...</span>
                            </>
                        )}
                    </div>

                    {/* <span className="fs-normal-light text-danger">{hintMessage}</span> */}
                </div>
            ) : (
                <div
                    className="position-relative d-flex gap-2 align-items-center flex-column py-4 px-5"
                    style={{ border: "2px dashed grey", borderRadius: "5px" }}
                >
                    <img
                        src={Doc22Icon}
                        height={28}
                        width={28}
                        alt="..."
                        onClick={() => {
                            toggleDocDialog({ filename: fileToView?.actualFileName });
                        }}
                    />
                    <span
                        className="fs-normal-med text-center text-break cursor-pointer"
                        onClick={() => {
                            handleViewFile(fileToView ?? ({} as Evidence));
                        }}
                    >
                        {fileToView.actualFileName}
                    </span>

                    {removeFile ? (
                        <div
                            className="position-absolute top-0 end-0 p-1 cursor-pointer"
                            onClick={removeFile}
                        >
                            <Close />
                        </div>
                    ) : null}
                </div>
            )}
            {errorMessage ? (
                <span className="fs-normal-light text-danger">{errorMessage}</span>
            ) : null}

            <MediaLayout
                title={fileToOpen?.actualFileName ?? "Media Preview"}
                open={openFile}
                handleClose={() => {
                    setOpenFile(false);
                }}
            >
                <ViewMedia
                    isPdf={isFilePdf}
                    url={fileUrl ?? ""}
                />
            </MediaLayout>
        </>
    );
}

export default FileUploadLayout;
