import { Box, Breadcrumbs, Button, Typography, Link, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, Tooltip } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { AppDispatch, RootState, store } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";


import { LoadingButton } from "@mui/lab";
import {
  AgreementType,
  DRAFT,
  NON_DRAFT,
  RM_ARBITRATION,
  RT_SELF,
  SEND_RESPONSE_TO_CLAIMANT,
  SENT_FOR_RAD_SCRUTINY,
} from "../../../constants/case-constants";
import {
  RESPONDENT_PRAYER,
  RESPONDENT_STATEMENT_OF_OBJECTION,
  RESPONDENT_SYNOPSIS,
} from "../../../constants/case-state";
import Objection from "./Dialog/counter-prayer";
import { useNavigate, useParams } from "react-router-dom";
import SynopsisList from "./synopsis/SynopsisList";
import PrayerList from "./prayers/PrayersList";
import StatementOfObjectionRow from "./StatementOfObjection/StatementOfObjectionRow";
import {
  addSynopsis,
  caseResponseThunk,
  goToNextSection,
  goToPrevSection,
  onClickTab,
  reSetResponseState,
  saveCaseFileResponseThunk,

  setCaseFileResponse,
  setResponse,
  validateResponse,
} from "../../../redux/slice/caseResponseSlice";
import { caseDetailThunk, validate } from "../../../redux/slice/caseSlice";

import CaseExpandView from "./CaseExpandView";

import NewWindow from "./referWindow";
import CustomLoader from "../../../components/common/CustomLoader";
import { Evidence } from "../../../model/caseDocument/synopsis";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { CASE_EXPAND, CASE_LIST, DASHBOARD, VIEW_CASE, CASE, CASE_DETAILS, FILE_OBJECTION } from "../../../routes/routes";
import { VIEW_CASE_DATA } from "../../../constants/local-storage-constants";
import SecureStorage from "../../../util/SecureStorage";
import ClaimantList from "../../case/components/Claimant/ClaimantList";
import DialogLayout from "../../../Layout/DialogLayout";
import { Editor } from "@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor";
import { CaseFileErrorModel } from "../../../model/fileCase";
import { useSnackbar } from "../../../context/SnackbarContext";
import { convertPermissionString } from "../../../util/stringUtils";
import { checkForChangesResponse } from "../../../util/case/checkForChangesResponse";
import { useCustomDialogBox } from "../../../context/CustomDialogContext";
import { initResponse } from "../../../redux/slice/components/initCaseResponseSlice";
import { SAVE_AS_DRAFT } from "../../../constants/btn-constants";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VerifyAffidavitConsentForm from "./common/VerifyAffidavitConsentForm";
import { UserRole } from "../../../model/userModel";


const RespondentResponse = () => {
  const dispatch: AppDispatch = useDispatch();

  const [showObjectionDialog, setShowObjectionDialog] = useState(false);
  const [openCancelDailouge, setOpenCancelDailouge] = useState(false);
  const [linkPrevious, setLinkPrevious] = useState("");

  const navigate = useNavigate();
  const [isOpen, setOpenState] = useState(false);


  const open = useCallback(() => setOpenState(true), []);
  const close = useCallback(() => setOpenState(false), []);


  const [openVerifyAffidavit, setOpenVerifyAffidavit] = useState(false);


  const { currentSection, caseFileResponse, caseFileResponseDetail, isResponseLoading } = useSelector(
    (state: RootState) => state.caseResponse
  );


  const { isLoading, isRespondentAdvocate, isAdvocate } = useSelector(
    (state: RootState) => state.case
  );
  const {
    caseFile,
    caseFileDetail,
    claimantList,
    respondentList
  } = useSelector((state: RootState) => state.case);

  const buttonList = [
    { label: "Cancel", id: 0 },
    { label: "Save As Draft", id: 1 },
    { label: "Next", id: 2 },
  ];
  const [activeButton, setActiveButton] = useState<number | null>(null);


  const { caseId, uniqueId } = useParams();


  useEffect(() => {
    if (caseId) {
      dispatch(caseDetailThunk({
        draftStatus: 1,
        uniqueId: caseId as string
      }))

    }
    if (uniqueId !== null && uniqueId !== undefined && uniqueId) {
      dispatch(caseResponseThunk({ uniqueId: uniqueId as string }))
    } else {

      dispatch(reSetResponseState({
        caseFileResponseDetail: {
          ...initResponse.caseFileResponseDetail,
          prayer: {
            ...initResponse.caseFileResponseDetail?.prayer,
            prayers: [
              ...caseFileDetail.prayers
            ]
          }
        }
      }))
    }


  }, [uniqueId, caseId])

  useEffect(() => {

    if ((!uniqueId) && caseFileDetail?.prayers[0]?.prayerDetail) {

      dispatch(reSetResponseState({
        caseFileResponseDetail: {
          ...initResponse.caseFileResponseDetail,
          prayer: {
            ...initResponse.caseFileResponseDetail?.prayer,
            prayers: [
              ...caseFileDetail.prayers
            ]
          }
        }
      }))
    }

  }, [caseFileDetail, uniqueId])



  const [openDialogClose, setDialogClose] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleCloseDialog = () => {
    setDialogClose(!openDialogClose);
  };
  const handleClose = () => {
    if (linkPrevious === "") {
      navigate(-1);
    } else {
      navigate(linkPrevious);
    }
  };

  const { showDialogBox } = useCustomDialogBox();
  const onViewCase = () => {
    const url = VIEW_CASE + "/" + uniqueId;
    // const url = CASE_EXPAND + "/" + uniqueId;
    SecureStorage.setItem(VIEW_CASE_DATA, {
      caseFile: { ...caseFile },
      claimantList: [...claimantList],
      respondentList: [...respondentList],
      caseFileDetail: caseFileDetail,
    })


    window.open(url, "_blank", "width=1000,height=600");
  }


  const SectionTabs = [
    {
      title: "Synopsis",
      id: RESPONDENT_SYNOPSIS,
      element: <SynopsisList />,
    },
    {
      title: "Statement of Defence",
      id: RESPONDENT_STATEMENT_OF_OBJECTION,
      element: <StatementOfObjectionRow />,
    },
    {
      title: "Response to Claim",
      id: RESPONDENT_PRAYER,
      element: <PrayerList />,
    },
  ];

  const handleAddMore = () => {
    dispatch(addSynopsis());
  };

  const onTabClick = (num: number) => {
    if (num < currentSection) {
      dispatch(onClickTab(num));
    } else {
      dispatch(validateResponse());
      const { isValid, errorMessage } = store.getState().caseResponse;

      if (isValid) {
        dispatch(onClickTab(num));
      } else {
        showSnackbar(errorMessage);
      }
    }

  };
  const handleNext = () => {
    setActiveButton(buttonList[2].id);



    dispatch(validateResponse());
    const { isValid, errorMessage } = store.getState().caseResponse;

    if (isValid) {
      if (currentSection === RESPONDENT_PRAYER) {
        const dataToSave = store.getState().caseResponse;
        if (isRespondentAdvocate) {
          const respondentAgreementType = caseFile.resolutionMethod === RM_ARBITRATION
            ? AgreementType.RESPONDENT_AFFIDAVIT
            : AgreementType.SECOND_PARTY_AFFIDAVIT;

          let resAffidavit = null as any
          let resConsentForm = null as any

          if (caseFile.resolutionMethod === RM_ARBITRATION) {
            resAffidavit = caseFileResponse?.caseAgreementList?.filter(agreement => agreement.agreementType === AgreementType.RESPONDENT_AFFIDAVIT)[0]
          } else {
            resAffidavit = caseFileResponse?.caseAgreementList?.filter(agreement => agreement.agreementType === AgreementType.SECOND_PARTY_AFFIDAVIT)[0]
          }

          resConsentForm = caseFileResponse?.caseAgreementList?.filter(agreement => agreement.agreementType === AgreementType.CONSENT_FORM)[0]

          if (!(resConsentForm && resAffidavit)) {
            setOpenVerifyAffidavit(true);
          } else {
            dispatch(
              saveCaseFileResponseThunk({
                ...dataToSave,
                caseFileResponse: {
                  ...dataToSave.caseFileResponse,
                  status: SEND_RESPONSE_TO_CLAIMANT,
                },
                uniqueId: caseId,
              })
            )
              .unwrap()
              .then(() => {
                setActiveButton(null);
                navigate(-1);
              })
              .catch((e) => {
                console.error(e);
                setActiveButton(null);
              });
          }

        } else {
          dispatch(
            saveCaseFileResponseThunk({
              ...dataToSave,
              caseFileResponse: {
                ...dataToSave.caseFileResponse,
                status: SENT_FOR_RAD_SCRUTINY,
              },
              uniqueId: caseId,
            })
          )
            .unwrap()
            .then(() => {
              setActiveButton(null);
              showDialogBox("Success", "Successfully sent to TruPact scrutiny")
              navigate(-1);
            })
            .catch((e) => {
              console.error(e);
              setActiveButton(null);
            });
        }

      } else {
        dispatch(goToNextSection());
        setActiveButton(null);
      }
    } else {
      showSnackbar(errorMessage);
      setActiveButton(null);

    }
    // if(buttonList[2].label==="send to TruPact scrutiny"){
    //   showDialogBox("Sucess","Successfully sent to TruPact scrutiny")
    // }


  };
  const handlePrev = () => {
    dispatch(goToPrevSection());
  };
  const handleCancel = (LinkPrev: string) => {
    // navigate(CASE_LIST);
    navigate(LinkPrev);
  };

  const onSaveAsDraft = () => {
    setActiveButton(buttonList[1].id);
    const dataToSave = store.getState().caseResponse;
    dispatch(
      saveCaseFileResponseThunk({
        ...dataToSave,
        caseFileResponse: {
          ...dataToSave.caseFileResponse,
        },
        uniqueId: caseFile.uniqueId,
      })
    )
      .unwrap()
      .then(() => {
        setActiveButton(null);
        if (linkPrevious === "") {
          navigate(-1);
        } else {
          navigate(linkPrevious);
        }
      })
      .catch((e) => {
        console.error(e);
        setActiveButton(null);
      });
  };

  if (isResponseLoading || isLoading) {
    return (
      <div className="vh-100 ">
        <CustomLoader />
      </div>
    );
  }

  const handleLinkPrev = (LinkPrev: string) => {
    setDialogClose(!openDialogClose);
    setLinkPrevious(LinkPrev);
  };

  return (

    <div className="vh-100 bg-light d-flex flex-column pt-2">
      <div className=" d-flex ">
        <div className=" px-4  pb-2  ">
          <img className="cursor-pointer"
            src="/rad-logo-v2.png"
            onClick={() => handleLinkPrev(DASHBOARD)}
            alt="logo"
            // width={105} 
            height="46"
          />
        </div>
        <div className="  pb-2 d-flex justify-content-center align-items-center" >
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <span className="fs-normal-light text-grey cursor-pointer" onClick={() => handleLinkPrev(DASHBOARD)}>Dashboard</span>
            <span className="fs-normal-light text-grey cursor-pointer" onClick={() => handleLinkPrev(CASE_LIST)}>Cases</span>
            <span className="fs-normal-light text-grey cursor-pointer" onClick={() => handleLinkPrev(CASE + "/" + caseId)}>Case Details</span>
            <span className="fs-normal-light text-dark cursor-pointer">Statement of Defence</span>
          </Breadcrumbs>
        </div>
        <div className="d-flex gap-2 justify-content-end ms-auto px-3 py-3">

          <div>
            {currentSection === RESPONDENT_PRAYER ? (
              <Button
                variant="outlined"
                sx={{ textTransform: "none" }}
                onClick={() => {
                  setShowObjectionDialog(true);
                }}
              >
                Counter Prayers
              </Button>
            ) : null}

          </div>
          <Button

            variant="outlined"
            sx={{ textTransform: "none" }}
            onClick={onViewCase}
          >
            View Case
          </Button>



        </div>

      </div>


      <div className=" flex-grow-1 d-flex justify-content-between overflow-hidden  ">
        <div className=" px-4 pb-2  ">
          {SectionTabs.map((data: any, index) => (
            <div
              className={`d-flex align-items-center gap-3 body-large bg-white p-4 p-md-2 mb-2 cursor-pointer ${currentSection === data.id ? "text-primary fw-bold" : ""
                }`}
              key={index}
              onClick={() => onTabClick(data.id)}
            >
              <Box
                sx={{
                  backgroundColor:
                    currentSection === data.id ? "#D7B24D" : "transparent",
                }}
                width={"4px"}
                height={"30px"}
              ></Box>

              <Typography variant="body1" className="fs-sm-lg-med">
                {data.title}
              </Typography>
            </div>
          ))}
        </div>


        <div className="  pb-2 flex-grow-1   ">


          <div className=" h-100 d-flex flex-column">

            {SectionTabs.map((section, i) => {
              if (section.id === currentSection) {
                return (
                  <React.Fragment key={i}>{section.element}</React.Fragment>
                );
              }
              return null;
            })}

            <div className="mt-auto pt-2">
              <div className="text-primary fw-bold w-100 d-flex justify-content-center">
                <div className="w-100 d-flex  ">
                  <div className="col d-flex justify-content-start align-items-end">
                    {SectionTabs.map((section, i) => {
                      if (
                        section.id === currentSection &&
                        section.id !== RESPONDENT_SYNOPSIS
                      ) {
                        return (
                          <Button
                            className="fs-normal-med p-0 fw-bold"
                            variant="text"
                            sx={{ textTransform: "none" }}
                            onClick={handlePrev}
                            key={i}
                          >
                            <ArrowBackIos
                              color="primary"
                              className="my-auto me-2 fs-normal-med"
                            />
                            Previous
                          </Button>
                        );
                      }
                      return <div key={i}></div>;
                    })}
                  </div>
                  <div className="col d-flex justify-content-center align-items-end gap-2">
                    {currentSection === RESPONDENT_SYNOPSIS ? (
                      <> <Button
                        className="fs-normal-med p-0 fw-bold"
                        variant="text"
                        sx={{ textTransform: "none" }}
                        onClick={() => {
                          handleAddMore();
                        }}
                      >
                        + Add more Synopsis
                      </Button>
                        <Tooltip title={"For each event of Synopsis, Click “Add more Synopsis”"}><InfoOutlinedIcon />
                        </Tooltip></>
                    ) : null}

                  </div>
                  <div className="col d-flex justify-content-end align-items-end">
                    {SectionTabs.map((section, i) => {
                      if (
                        currentSection !== RESPONDENT_PRAYER &&
                        section.id === currentSection
                      ) {
                        return (
                          <Button
                            className="fs-normal-med p-0 fw-bold"
                            variant="text"
                            sx={{ textTransform: "none" }}
                            onClick={handleNext}
                            key={i}
                          >
                            Next
                            <ArrowForwardIos
                              color="primary"
                              className="my-auto ms-2 fs-normal-med"
                            />
                          </Button>
                        );
                      }
                      return <div key={i}></div>;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" px-4 pb-2 mb-4  overflow-hidden ">
          <div className="  h-100 overflow-auto ">

          </div>
        </div>
      </div>

      <div className=" bg-white d-flex justify-content-end p-3 gap-3">
        <LoadingButton
          disabled={activeButton ? true : false}
          loading={buttonList[0].id === activeButton}
          sx={{ textTransform: "none" }}
          variant="outlined"
          onClick={() => setDialogClose(true)}
        >
          {buttonList[0].label}
        </LoadingButton>
        <LoadingButton
          disabled={activeButton ? true : false}
          loading={buttonList[1].id === activeButton}
          sx={{ textTransform: "none" }}
          variant="outlined"
          onClick={() => {
            setActiveButton(SAVE_AS_DRAFT);
            onSaveAsDraft();
          }}
        >
          {buttonList[1].label}
        </LoadingButton>


        {currentSection === RESPONDENT_PRAYER ? (
          <LoadingButton
            disabled={activeButton ? true : false}
            loading={buttonList[2].id === activeButton}
            sx={{ textTransform: "none" }}
            variant="contained"
            onClick={handleNext}
          >
            {currentSection !== RESPONDENT_PRAYER
              ? buttonList[2].label
              : isRespondentAdvocate || isAdvocate
                ? caseFile.resolutionMethod === RM_ARBITRATION ?
                  "Send Response to Claimant" : "Send Response to First Party"
                : "Send to TruPact scrutiny"}
          </LoadingButton>
        ) : null}

      </div>

      {currentSection === RESPONDENT_PRAYER ? (
        <Objection
          onClose={() => {
            setShowObjectionDialog(false);
          }}
          open={showObjectionDialog}
        />
      ) : null}

      {/* {isOpen && (
        <NewWindow close={close}>
          <CaseExpandView isRefer={true} />
        </NewWindow>
      )} */}

      <Dialog
        open={openDialogClose}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure ?</DialogTitle>
        <DialogContent className="pb-0" sx={{ width: 600 }}>
          <DialogContentText className=" p-0" id="alert-dialog-description">
            Please make sure to save your data before closing. Exiting without
            saving will result in the loss of unsaved work.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            variant="text"
            sx={{ textTransform: "none" }}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{ textTransform: "none" }}
          >
            Exit without saving
          </Button>
          <LoadingButton
            className="mx-3 fw-bold"
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={onSaveAsDraft}
          >
            Save as Draft
          </LoadingButton>
        </DialogActions>
      </Dialog>



      <VerifyAffidavitConsentForm
        open={openVerifyAffidavit}
        onClose={() => {
          setOpenVerifyAffidavit(false)
          setActiveButton(null)
        }} role={UserRole.RESPONDENT} />
    </div>
  );
};

export default RespondentResponse;
