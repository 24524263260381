import { Badge, Box } from "@mui/material";
import React from "react";

interface NotificationCardProps {
  imgUrl: string | null;
  userName: string;
  content: string;
  read: boolean;
  time: string;
  onClick: () => void
}

const NotificationCard = ({
  imgUrl,
  userName,
  content,
  time,
  read,
  onClick,
}: NotificationCardProps) => {
  return (
    <Box className="d-flex gap-3 m-2 cursor-pointer" onClick={onClick}>
      <div>
        <Badge variant={read ?"standard":"dot"} color="error">
          {/* {imgUrl ? (
            <img
              className="rounded-circle"
              src={imgUrl}
              alt="profile"
              width="36.4892578125px"
              height="36.1602783203125px"
            />
          ) : ( */}
          <Box
            className="d-flex justify-content-center align-items-center rounded-circle bg-light-blue1 text-primary fw-bold"
            width="36.4892578125px"
            height="36.1602783203125px"
          >
            {userName[0].toUpperCase()}
          </Box>
          {/* )} */}
        </Badge>
      </div>
      <Box className="me-3 gap-5">
        <div className="fs-sm-lg-semibold">{userName}</div>
        <div className="fs-normal-light  text-grey" style={{ lineHeight: '20px' }}>{content}</div>
        <div>
          <div className="fs-sm-light py-1 px-2 rounded-2 bg-light-blue2 d-inline-block">
            {time}
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default NotificationCard;
