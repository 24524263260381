import React, { ReactNode, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EDIT_CASE, VERSION_COMPARISON } from "../../../routes/routes";
import { editClaimant, editDetailedFacts, editGrounds, editRespondent, editSynopsis, setCaseDateSlice, setCurrentSection } from "../../../redux/slice/caseSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState, store } from "../../../redux/store";
import CloseIcon from "@mui/icons-material/Close";

import {
  CLAIMANT_DETAILS,
  COUNTER_PRAYER,
  DETAILED_FACTS,
  EVIDENCES,
  GROUNDS_REASONING,
  PRAYERS,
  REPRESENTATION_TYPE,
  RESPONDENT_DETAILS,
  RESPONDENT_PRAYER,
  RESPONDENT_STATEMENT_OF_OBJECTION,
  RESPONDENT_SYNOPSIS,
  SYNOPSIS,
} from "../../../constants/case-state";
import {
  isAcceptRejectEnabled,
  isViewEditEnabled,
} from "../../../util/permission/role-permission";
import {
  CD,
  DF,
  GR,
  PR,
  RD,
  RP,
  RT,
  SO,
  SY,
} from "../../../constants/permission";
import { Box, Button, Drawer, IconButton, Tab, Tabs, TextField } from "@mui/material";
import {
  SCRUTINY_RESOLVED,
  SCRUTINY_REVIEW,
  SENT_FOR_RAD_SCRUTINY,
  SENT_FOR_RESCRUTINY,
} from "../../../constants/case-constants";

import { checkForResponse } from "../../../util/case/checkForResponse";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../../constants/nav-constants";
import ShowCommentIcon from "./common/ShowCommentIcon";
import { RejectStatus } from "../../../model/caseDocument/synopsis";
import { ShowLabel } from "../../../components/common/ShowLabel";
import { StatusType } from "../../../model/fileCase";
import EditButton from "./common/EditButton";
import ScrutinyNote from "./common/ScrutinyNote";
import { editResponseStatementOfObj, editResponseSynopsis, setResponseCurrentSection } from "../../../redux/slice/caseResponseSlice";
import secureLocalStorage from "react-secure-storage";
import SecureStorage from "../../../util/SecureStorage";
import {
  CURRENT_SECTION_KEY,
  EDIT_CASE_DETAILS,
} from "../../../constants/local-storage-constants";
import { log } from "console";
import { get } from "http";
import { useCustomDialogBox } from "../../../context/CustomDialogContext";
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';


const boxStyle = {
  border: "1px solid #E5E5FF",
  borderRadius: "5px",
};
const boxHeaderStyle = {
  borderBottom: "2px solid #E5E5FF",
  borderRadius: "5px 5px 0 0",
};

interface ViewMoreContainerProps {
  sectionId: number;
  label: string;
  showLoadBtn: boolean;
  loadBtnStatus: boolean;
  setLoadBtnStatus: React.Dispatch<React.SetStateAction<boolean>>;
  isVersionPage?:boolean ;
  isRespondentData?: boolean; 

  scrutinyCount: number|null;
  enableEdit?: boolean;

  
  children: ReactNode;
}

const ViewMoreContainer: React.FC<ViewMoreContainerProps> = ({
  sectionId,
  label,
  showLoadBtn,
  loadBtnStatus,
  setLoadBtnStatus,
isRespondentData,
scrutinyCount,
enableEdit,
  isVersionPage,
  children,
}) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const handleLoadBtn = () => {
    setLoadBtnStatus(!loadBtnStatus);
  };


  
  const isVersionComparision = useLocation().pathname.split('/')[1] === VERSION_COMPARISON.split('/')[1]

  const {
    claimantList,
    respondentList,
    caseFileDetail,
    caseFile,
    isRespondent,
    hasResponse,
    isClaimant,
    isClaimantAdvocate,
    isEdited,
    isAdmin,
    isAdvocate,
    permissionState,
  } = store.getState().case;

  const {
    caseFileResponseDetail,
    caseFileResponse,
    permissionState: permissionStateResponse,
  } = useSelector((state: RootState) => state.caseResponse);


  const getRejectedCount = (id: number) => {
    
    if (hasResponse && (caseFileResponse?.status === SENT_FOR_RESCRUTINY || caseFileResponse?.status === SCRUTINY_REVIEW) && (isAdmin || isClaimant || isRespondent)) {
      if (caseFileResponseDetail) {
        if (id === RESPONDENT_SYNOPSIS) {
          let count = 0 as any;

          for (let i = 0; i < caseFileResponseDetail.synopsis.length; i++) {
            if (
              caseFileResponseDetail.synopsis[i].rejected ===
              RejectStatus.REJECTED
            ) {
              count++;
            }
          }
          if(count){
            return count;
          }else{
            return null
          }
        }
        if(id===EVIDENCES){
          let count = 0 as any;

          for (let i = 0; i < caseFileResponseDetail.synopsis.length; i++) {
             count += caseFileResponseDetail.synopsis[i].evidences.filter((data: any) => data.reUpload===1).length;
          }
          if (count) {
            return count;
          } else {
            return null
          }
        }
      }
    } else if ((caseFile?.status === SENT_FOR_RESCRUTINY || caseFile?.status === SCRUTINY_REVIEW) && (isAdmin || isClaimant || isRespondent)) {
      if (id === SYNOPSIS) {
        let count = 0;
        for (let i = 0; i < caseFileDetail.synopsis.length; i++) {
          if (caseFileDetail.synopsis[i].rejected === RejectStatus.REJECTED) {
            count++;
          }
        }
        if ((caseFile?.status === SENT_FOR_RESCRUTINY || caseFile?.status === SCRUTINY_REVIEW) && (isAdmin || isClaimant || isRespondent) && count) {

          return count.toString();
        } else {
          return null
        }
      }
      if (id === EVIDENCES) {
        let count = 0 as any;

        for (let i = 0; i < caseFileDetail.synopsis.length; i++) {
          count = count + caseFileDetail.synopsis[i].evidences.filter((data: any) => data.reUpload === 1).length;
        }
        if (count) {
          return count;
        } else {
          return null
        }
      }
      if (id === GROUNDS_REASONING) {
        let count = 0;
        for (let i = 0; i < caseFileDetail.grounds.length; i++) {
          if (caseFileDetail.grounds[i].rejected === RejectStatus.REJECTED) {
            count++;
          }
        }
        if ((caseFile?.status === SENT_FOR_RESCRUTINY || caseFile?.status === SCRUTINY_REVIEW) && (isAdmin || isClaimant || isRespondent) && count) {

          return count.toString();
        } else {
          return null
        }
      }
    }

    if (id === CLAIMANT_DETAILS) {
      let count = 0;
      for (let i = 0; i < claimantList.length; i++) {
        if (claimantList[i].rejected === RejectStatus.REJECTED) {
          count++;
        }
      }
      if((caseFile?.status === SENT_FOR_RESCRUTINY || caseFile?.status === SCRUTINY_REVIEW) && (isAdmin || isClaimant || isRespondent) && count){

        return count.toString();
      }else{
        return null
      }

    }
    if (id === RESPONDENT_DETAILS) {
      let count = 0;

      for (let i = 0; i < respondentList.length; i++) {
        if (respondentList[i].rejected === RejectStatus.REJECTED) {
          count++;
        }
      }

      if ((caseFile?.status === SENT_FOR_RESCRUTINY || caseFile?.status === SCRUTINY_REVIEW) && (isAdmin || isClaimant || isRespondent) && count) {

        return count.toString();
      } else {
        return null
      }
    }

    if (id === DETAILED_FACTS) {
      let count = 0;
      count = caseFileDetail?.detailedFacts?.rejected === RejectStatus.REJECTED ? 1 : 0

      if ((caseFile?.status === SENT_FOR_RESCRUTINY || caseFile?.status === SCRUTINY_REVIEW) && (isAdmin || isClaimant || isRespondent) && count) {
        return count.toString();
      } else {
        return null
      }
    }

    if (id === RESPONDENT_STATEMENT_OF_OBJECTION) {
      let count = 0;
      count = caseFileResponseDetail?.statementOfObjection?.rejected === RejectStatus.REJECTED ? 1 : 0

      if ((caseFileResponse?.status === SENT_FOR_RESCRUTINY || caseFileResponse?.status === SCRUTINY_REVIEW) && (isAdmin || isClaimant || isRespondent) && count) {
        return count.toString();
      } else {
        return null
      }
    }
  };




  const handleEdit = () => {
    SecureStorage.setItem(CURRENT_SECTION_KEY, sectionId); //? To know which section to edit
    SecureStorage.setItem(EDIT_CASE_DETAILS, true);
    dispatch(setCaseDateSlice({key:"isEdited",value:true}))

    if (isRespondentData) {
      dispatch(setResponseCurrentSection(sectionId));
    } else {
      dispatch(setCurrentSection(sectionId));
    }
    navigate(EDIT_CASE + "/" + caseFile.uniqueId);
  };


  const { roleRid } =
    store.getState().profileDetail.profileDetail.personalDetail;




  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const { showDialogBox } = useCustomDialogBox();




  let dataToRender = [] as any;
  if (sectionId === CLAIMANT_DETAILS) {
    dataToRender = claimantList;
  }
  if (sectionId === RESPONDENT_DETAILS) {
    dataToRender = respondentList;
  }
  if (sectionId === SYNOPSIS) {
    dataToRender = caseFileDetail.synopsis;
  }
  if (sectionId === GROUNDS_REASONING) {
    dataToRender = caseFileDetail.grounds;
  }
  if (sectionId === RESPONDENT_SYNOPSIS) {
    dataToRender = caseFileResponseDetail.synopsis;
  }
  if (sectionId === DETAILED_FACTS) {
    dataToRender = [ caseFileDetail.detailedFacts ];
  }
  if (sectionId === RESPONDENT_STATEMENT_OF_OBJECTION) {
    dataToRender = [ caseFileResponseDetail.statementOfObjection ];
  }

  const handleResolutionChange = (index: number, value: string, key: string) => {
    switch (sectionId) {
      case CLAIMANT_DETAILS:
        dispatch(editClaimant({ index, key, value }));
        break;
      case RESPONDENT_DETAILS:
        dispatch(editRespondent({ index, key, value }));
        break;
      case SYNOPSIS:
        dispatch(editSynopsis({ index, key, value }));
        break;
      case GROUNDS_REASONING:
        dispatch(editGrounds({ index, key, value }));
        break;
      case RESPONDENT_SYNOPSIS:
        dispatch(editResponseSynopsis({ index, key, value }));
        break;
      case DETAILED_FACTS:
        dispatch(editDetailedFacts({ index, key, value }));
        break;
      case RESPONDENT_STATEMENT_OF_OBJECTION:
        dispatch(editResponseStatementOfObj({ index, key, value }));
        break;
      default:
        break;
    }
  };

  const onClickAllow = (index: number) => {
    switch (sectionId) {
      case CLAIMANT_DETAILS:
        dispatch(editClaimant({ index, key: "resolved", value: true }));
        break;
      case RESPONDENT_DETAILS:
        dispatch(editRespondent({ index, key: "resolved", value: true }));
        break;
      case SYNOPSIS:
        dispatch(editSynopsis({ index, key: "resolved", value: true }));
        break;
      case GROUNDS_REASONING:
        dispatch(editGrounds({ index, key: "resolved", value: true }));
        break;
      case RESPONDENT_SYNOPSIS:
        dispatch(editResponseSynopsis({ index, key: "resolved", value: true }));
        break;
      case DETAILED_FACTS:
        dispatch(editDetailedFacts({ index, key: "resolved", value: true }));
        break;
      case RESPONDENT_STATEMENT_OF_OBJECTION:
        dispatch(editResponseStatementOfObj({ index, key: "resolved", value: true }));
        break;
      default:
        break;
    }

    
    const isResolved = dataToRender.filter((data: any) => data.rejected === RejectStatus.REJECTED&&!data.resolved).length===1;   
    if (isResolved) {
      toggleDrawer();
    }
    showDialogBox("Success", "Scrutiny Resolved");
  };

  const onClickCancel = (index: number) => {
    switch (sectionId) {
      case CLAIMANT_DETAILS:
        dispatch(editClaimant({ index, key: "resolved", value: false }));
        dispatch(editClaimant({ index, key: "resolution", value: "" }));
        break;
      case RESPONDENT_DETAILS:
        dispatch(editRespondent({ index, key: "resolved", value: false }));
        dispatch(editRespondent({ index, key: "resolution", value: "" }));
        break;
      case SYNOPSIS:
        dispatch(editSynopsis({ index, key: "resolved", value: false }));
        dispatch(editSynopsis({ index, key: "resolution", value: "" }));
        break;
      case GROUNDS_REASONING:
        dispatch(editGrounds({ index, key: "resolved", value: false }));
        dispatch(editGrounds({ index, key: "resolution", value: "" }));
        break;
      case RESPONDENT_SYNOPSIS:
        dispatch(editResponseSynopsis({ index, key: "resolved", value: false }));
        dispatch(editResponseSynopsis({ index, key: "resolution", value: "" }));
        break;
      default:

        break;
    }

  };


  const getResolutionValue = (index: number) => {
    if (sectionId === CLAIMANT_DETAILS) {
      return claimantList[index].resolution;
    } else if (sectionId === RESPONDENT_DETAILS) {
      return respondentList[index].resolution;
    } else if (sectionId === SYNOPSIS) {

      return caseFileDetail.synopsis[index].resolution;

    } else if (sectionId === GROUNDS_REASONING) {

      return caseFileDetail.grounds[index].resolution;

    } else if (sectionId === RESPONDENT_SYNOPSIS) {

      return caseFileResponseDetail.synopsis[index].resolution;
    } else if (sectionId === DETAILED_FACTS) {
      return caseFileDetail.detailedFacts.resolution;
    } else if (sectionId === RESPONDENT_STATEMENT_OF_OBJECTION) {
      return caseFileResponseDetail.statementOfObjection?.resolution as string;
    } else {
      return "";
    }
  };

  const getHeaderText = (index: number) => {
    if (sectionId === CLAIMANT_DETAILS) {
      return claimantList[index].name;
    } else if (sectionId === RESPONDENT_DETAILS) {
      return respondentList[index].name;
    } else if (sectionId === SYNOPSIS) {

      return caseFileDetail.synopsis[index].eventDetail;

    } else if (sectionId === GROUNDS_REASONING) {

      return caseFileDetail.grounds[index].groundsDetail;

    } else if (sectionId === RESPONDENT_SYNOPSIS) {

      return caseFileResponseDetail.synopsis[index].eventDetail;

    } else {
      return "";
    }
  };

  const getSectionName = (selectedSectionId: any) => {
    if (selectedSectionId === CLAIMANT_DETAILS) {
      return "Claimant Section";
    } else if (selectedSectionId === RESPONDENT_DETAILS) {
      return "Respondent Section";
    } else if (selectedSectionId === SYNOPSIS) {
      return "Synopsis Section";
    } else if (selectedSectionId === GROUNDS_REASONING) {
      return "Grounds and reasoning Section";
    } else if (selectedSectionId === DETAILED_FACTS) {
      return "Detailed Facts";
    } else if (selectedSectionId === RESPONDENT_STATEMENT_OF_OBJECTION) {
      return "Statement of Defence";
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="position-relative">
        <div className="p-0" style={boxStyle}>
          <div
            className="d-flex gap-3 align-items-center bg-light-blue2 px-3 py-1"
            style={boxHeaderStyle}
          >
            <div className="fs-normal-light flex-grow-1 p-0 m-0">{label}</div>
            {
              (scrutinyCount!==undefined&&scrutinyCount!==null && !isVersionComparision) && (
                <div onClick={() => {
                  handleLoadBtn();
                }}
                >
                  <ScrutinyNote scrutinyCount={scrutinyCount} />
                </div>
              )
            }




                {/* section.id === sectionId &&
                (getRejectedCount(sectionId) ?? 0) > 0 &&
                (caseFile.status === SENT_FOR_RESCRUTINY ||
                  caseFileResponse?.status === SENT_FOR_RESCRUTINY) &&
                (roleRid === ROLE_ADMIN || roleRid === ROLE_SUPER_ADMIN)&&
                 !isVersionComparision
               */}    
            {!isVersionPage?(
              <div onClick={sectionId !== EVIDENCES ? toggleDrawer : handleLoadBtn}>
                {
                  (sectionId === EVIDENCES && isAdmin && (caseFileResponse.status === SCRUTINY_REVIEW||caseFile.status === SCRUTINY_REVIEW))
                  ? ""
                  : <ShowLabel
                    label={(isAdmin && caseFileResponse.status !== SCRUTINY_RESOLVED) ? getRejectedCount(sectionId) : null}
                    secondaryLabel={
                      sectionId === EVIDENCES ? "Re-Uploaded" : "Rejection"
                    }
                    status={StatusType.DANGER}
                  />
                }

                {!isVersionComparision && getRejectedCount(sectionId) > 0 && sectionId !== EVIDENCES ? (
                  <CommentOutlinedIcon />
                  // <ShowCommentIcon sectionId={sectionId} />
                ) : null}
              </div>
            ):null}
           

            <EditButton   onClick={enableEdit?handleEdit:null} />
              
          </div>
    

          {children}
        </div>

        {showLoadBtn && (
          <div
            className="position-absolute top-100 start-50 translate-middle bg-white cursor-pointer px-3 py-1"
            style={boxStyle}
            onClick={handleLoadBtn}
          >
            <div className="d-flex gap-2 align-items-center">
              {loadBtnStatus ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  fill="currentColor"
                  className="bi bi-chevron-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  fill="currentColor"
                  className="bi bi-chevron-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              )}
              <span className="fs-sm-med text-primary">
                {loadBtnStatus ? "View Less" : "View More"}
              </span>
            </div>
          </div>
        )}
      </div>

      <Drawer
        open={open}
        anchor="right"
        onClose={toggleDrawer}
        sx={{
          width: "35%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "35%",
            boxSizing: "border-box",
          },
        }}
      >
        <div className="p-3 d-flex gap-3 align-items-center w-100">
          <div className="fs-med-lg-semibold d-flex flex-grow-1">
            Reason of rejection {getSectionName(sectionId)}
          </div>
          <IconButton className="border border-1" onClick={toggleDrawer}>
            <CloseIcon color="primary" />
          </IconButton>
        </div>

        <div className="px-3">

          {dataToRender?.map(
            (data: any, i: number) =>
              data?.rejected === RejectStatus.REJECTED && (
                <div key={i}>
                  <div className="d-flex gap-3">
                    {dataToRender.length > 1 && (
                      <Box
                        className="rounded-circle p-3 bg-light-blue1 d-flex align-items-center justify-content-center"
                        width={3.5}
                        height={3.5}
                      >
                        {i + 1}
                      </Box>
                    )}
                    <span className="text-grey">{getHeaderText(i)}</span>

                    <div></div>
                  </div>
                  <div className="py-2 px-3 bg-light-pink my-3 ">
                    {" "}
                    {data.reason}
                  </div>

                  {isAdmin || isAdvocate ? (
                    <>
                      <TextField

                        className="w-100"
                        variant="standard"
                        name="resolution"
                        disabled={data.resolved}
                        onChange={(e) => {
                          handleResolutionChange(i, e.target.value, e.target.name);
                        }}
                        placeholder="Add Your Notes"
                        value={getResolutionValue(i)}
                      />

                      {caseFile.status === SENT_FOR_RESCRUTINY || caseFileResponse.status === SENT_FOR_RESCRUTINY ?
                        (<div className="d-flex w-100 gap-3 justify-content-end mt-3">
                          <Button
                            variant="text"
                            sx={{ textTransform: "none" }}
                            onClick={() => {
                              onClickCancel(i);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={data.resolved ? true : false}
                            variant="contained"
                            sx={{ textTransform: "none" }}
                            onClick={() => {
                              onClickAllow(i);
                            }}
                          >
                            {data.resolved ? "Resolved" : "Resolve"}
                          </Button>
                        </div>) : null
                      }

                    </>
                  ) : null}
                </div>
              )
          )}
        </div>
      </Drawer>


    </>
  );
};

export default ViewMoreContainer;
