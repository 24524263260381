import React, { useState } from 'react'
import ViewCase from './ViewCase'
import SecureStorage from '../../../util/SecureStorage'
import { VIEW_CASE_DATA } from '../../../constants/local-storage-constants'
import StringDifferenceHighlighter from '../../../components/StringDifferenceHighlighter'
import { CLAIMANT_DETAILS, DETAILED_FACTS, EVIDENCES, GROUNDS_REASONING, PRAYERS, RESPONDENT_DETAILS, SYNOPSIS } from '../../../constants/case-state'
import { CD } from '../../../constants/permission'
import ViewMoreContainer from './ViewMoreContainer'
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Evidence, SynopsisModel } from '../../../model/caseDocument/synopsis'
import { DRAFT, NEW_CASE, RM_CONCILIATION, RM_MEDIATION } from '../../../constants/case-constants'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MediaLayout from '../../../Layout/MediaLayout'
import ViewMedia from '../../../components/ViewMedia'
import { ShowLabel } from '../../../components/common/ShowLabel'
import { StatusType } from '../../../model/fileCase'
import { formatDateTime } from '../../../util/stringUtils'
import { DateOption } from "../../../redux/slice/components/initCaseSliceState";
import { Tooltip } from '@mui/material'





function ReferViewCase() {



  const { caseFile, claimantList, respondentList, caseFileDetail }: any = SecureStorage.getItem(VIEW_CASE_DATA)



  const [openFile, setOpenFile] = useState(false);
  const [isFilePdf, setIsFilePdf] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [fileToOpen, setFileToOpen] = useState<Evidence | null>(null);


  const handleViewFile = async (evidence: Evidence) => {
    const url = [
      caseFile.status === NEW_CASE ? DRAFT : caseFile.status,
      caseFile.uniqueId ?? "",
      "SYNOPSIS",
      evidence.uploadedFileName,
    ].join("/");

    setIsFilePdf(url.endsWith(".pdf"));
    setOpenFile(true);
    setFileUrl(url);
    setFileToOpen(evidence);
  };


  const getIntensity = (scale: number) => {
    if (scale >= 0 && scale <= 3) {
      return "Low";
    } else if (scale >= 4 && scale <= 7) {
      return "Medium";
    } else if (scale >= 8 && scale <= 10) {
      return "High";
    } else {
      return "";
    }
  };


  return (
    <>
      <div className="d-flex flex-column vh-100 bg-light">
        <div className="d-flex align-items-center bg-light-blue1 px-4 py-2">
          <div className="fs-sm-lg-light flex-grow-1 p-0 m-0">
            Case {caseFile.caseId} /{" "}
            <span className="text-capitalize ms-1">
              {caseFile.resolutionMethod?.toLowerCase().replace(/_/g, " ")}
            </span>
          </div>



        </div>
        <div className="flex-grow-1 overflow-auto p-4">
          <div
            className="bg-white mx-auto"
            style={{ width: "80%", borderRadius: "5px" }}
          >
            <ViewMoreContainer
              sectionId={CLAIMANT_DETAILS}
              label={caseFile.resolutionMethod === RM_CONCILIATION || caseFile.resolutionMethod === RM_MEDIATION ? "First Party Details" : "Claimant Details"}
              showLoadBtn={false}
              loadBtnStatus={true}
              setLoadBtnStatus={() => { }}
              scrutinyCount={null}

            >

              <div className="px-3 py-4">
                <div className="row row-cols-2 g-5">
                  {claimantList?.map((data: any, key: number) => (
                    <div className="col" key={key}>
                      <div className="d-flex gap-2 align-items-center">
                        <span className="fs-normal-light p-0 m-0">
                          {key + 1}.
                        </span>
                        <span className="fs-normal-med p-0 m-0">

                          <>{data.name}</>

                        </span>
                      </div>
                      <div className="fs-normal-light p-0 ms-3">

                        <>{data.address}</>

                      </div>
                      <div className="fs-normal-light p-0 ms-3">

                        <>{data.mobileNo}</>

                      </div>
                      <Tooltip title={data.email}>
                      <div className="fs-normal-light p-0 ms-3" style={{
                   display: 'inline-block',
                   maxWidth: '200px',
                   overflow: 'hidden',
                   whiteSpace: 'nowrap',
                   textOverflow: 'ellipsis',
                   verticalAlign: 'middle',
                    }}>

                        <>{data.email}</>

                      </div>
                      </Tooltip>


                    </div>
                  ))}
                </div>
              </div>

            </ViewMoreContainer>
            <ViewMoreContainer
              sectionId={RESPONDENT_DETAILS}
              label={caseFile.resolutionMethod === RM_CONCILIATION || caseFile.resolutionMethod === RM_MEDIATION ? "Second Party Details" : "Respondent Details"}
              showLoadBtn={false}
              loadBtnStatus={true}
              setLoadBtnStatus={() => { }}
              scrutinyCount={null}

            >

              <div className="px-3 py-4">
                <div className="row row-cols-2 g-5">
                  {respondentList?.map((data: any, key: number) => (
                    <div className="col" key={key}>
                      <div className="d-flex gap-2 align-items-center">
                        <span className="fs-normal-light p-0 m-0">
                          {key + 1}.
                        </span>
                        <span className="fs-normal-med p-0 m-0">

                          <>{data.name}</>

                        </span>
                      </div>
                      <div className="fs-normal-light p-0 ms-3">

                        <>{data.address}</>

                      </div>
                      <div className="fs-normal-light p-0 ms-3">

                        <>{data.mobileNo}</>

                      </div>
                      <Tooltip title={data.email}>
                      <div className="fs-normal-light p-0 ms-3" style={{
                      display: 'inline-block',
                      maxWidth: '200px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      verticalAlign: 'middle',
                    }}>

                        <>{data.email}</>

                      </div>
                      </Tooltip>


                    </div>
                  ))}
                </div>
              </div>

            </ViewMoreContainer>


            <ViewMoreContainer
              sectionId={SYNOPSIS}
              label={"Synopsis"}
              showLoadBtn={false}
              loadBtnStatus={true}
              setLoadBtnStatus={() => { }}
              scrutinyCount={null}

            >

              <div className="px-3 py-4">
                <div className="d-flex align-items-center h-100">
                  <div
                    className="flex-shrink-0 p-2"
                    style={{ border: "0.5px solid #E5E5FF", flexBasis: "20%" }}
                  >
                    <p className="fs-normal-med text-center p-0 m-0">Date </p>
                  </div>
                  <div
                    className="flex-grow-1 p-2"
                    style={{ border: "0.5px solid #E5E5FF" }}
                  >
                    <p className="fs-normal-med text-center p-0 m-0">Events</p>
                  </div>
                  <div
                    className="flex-shrink-0 p-2"
                    style={{ border: "0.5px solid #E5E5FF", flexBasis: "20%" }}
                  >
                    <p className="fs-normal-med text-center p-0 m-0">Evidences</p>
                  </div>
                </div>

                <div className="d-flex gap-3 flex-column">
                  {caseFileDetail.synopsis?.map((data: any, key: number) => (
                    <div className="d-flex h-100 flex-column" key={key}>
                      <div className="d-flex">
                        <div
                          className="flex-shrink-0 d-flex justify-content-center align-items-center p-2"
                          style={{
                            border: "0.5px solid #E5E5FF",
                            flexBasis: "20%",
                          }}
                        >
                          {/* <p className="fs-normal-light text-center p-0 m-0">
                          {data.dateTime}
                        </p> */}
                          <p className="fs-normal-light text-center p-0 m-0">
                            {/* {data?.dates?.length?formatDateTime(data.dates[0],false):null} */}

                            {data.dateType === DateOption.NOT_APPLICABLE
                              ? "N/A"
                              : data?.dates?.length
                                ? data.dates
                                  .map((date: string | null) =>
                                    data.dateType !== DateOption.ONLY_YEAR ? (
                                      formatDateTime(date, false)
                                    ) : (
                                      date
                                    )
                                  )
                                  .join(data.dateType === DateOption.RANGE_OF_DATES ? " - " : " , ")
                                : null}

                          </p>
                        </div>

                        <div
                          className="flex-grow-1 p-2"
                          style={{ border: "0.5px solid #E5E5FF" }}
                        >
                          <div className="fs-normal-light text-start p-0 m-0">
                            {/* {((isUnderScrutinyReview &&
                          data?.hasChanges &&
                          !isAdmin) || showHighlighter) ? (
                          <>
                            <StringDifferenceHighlighter
                              original={
                               previousSynopsis[key]
                                  ?.eventDetail as string
                              }
                              modified={data?.eventDetail}
                            />
                          </>
                        ) : ( */}
                            <>{data?.eventDetail}</>
                            {/* )} */}
                          </div>
                          {/* {isAcceptRejectEnabled(SY,
                        hasResponse
                          ? permissionStateResponse
                          : permissionState
                      ) &&
                        data.hasChanges && (
                          <>
                            <RejectApproveBtns
                              dataKey={SYNOPSIS}
                              index={key}
                            />
                          </>
                        )} */}
                        </div>
                        <div
                          className="flex-shrink-0 d-flex justify-content-center align-items-center p-2"
                          style={{
                            border: "0.5px solid #E5E5FF",
                            flexBasis: "20%",
                          }}
                        >
                          <p className="fs-normal-light text-center p-0 m-0">
                            DR {data.evidences.length}

                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

            </ViewMoreContainer>

            <ViewMoreContainer
              sectionId={DETAILED_FACTS}
              label="Detailed Facts"
              showLoadBtn={false}
              loadBtnStatus={true}
              setLoadBtnStatus={() => { }}
              scrutinyCount={null}

            >

              <div className="px-3 py-4">
                <div className="fs-normal-light text-start p-0 m-0"
                  dangerouslySetInnerHTML={{
                    __html: caseFileDetail.detailedFacts.factsDetail,
                  }}
                ></div>
              </div>

            </ViewMoreContainer>



            <ViewMoreContainer
              sectionId={GROUNDS_REASONING}
              label="Grounds /  Reasonings"
              showLoadBtn={false}
              loadBtnStatus={true}
              setLoadBtnStatus={() => { }}
              scrutinyCount={null}

            >
              <div className="px-3 py-4">
                <div className="d-flex gap-3 flex-column">
                  {caseFileDetail.grounds?.map((data: any, key: number) => (
                    <div className="d-flex h-100" key={key}>
                      <div className="flex-shrink-0 d-flex p-2">{key + 1}</div>
                      <div className="flex-grow-1 p-2">
                        <p className="fs-normal-light text-start p-0 m-0">

                          <>{data.groundsDetail}</>

                        </p>

                      </div>
                    </div>
                  ))}
                </div>
              </div>

            </ViewMoreContainer>


            <ViewMoreContainer
              sectionId={PRAYERS}
              label="Prayers"
              showLoadBtn={false}
              loadBtnStatus={true}
              setLoadBtnStatus={() => { }}
              scrutinyCount={null}

            >

              <div className="px-3 py-4">
                <div className="d-flex gap-3 flex-column">
                  {caseFileDetail.prayers?.map((data: any, key: number) => (
                    <div className="d-flex h-100" key={key}>
                      <div className="flex-shrink-0 d-flex p-2">{key + 1}</div>
                      <div className="flex-grow-1 p-2">
                        <p className="fs-normal-light text-start p-0 m-0">

                          <>{data.prayerDetail}</>

                        </p>
                        <span
                          className={` fs-normal-light 
                              }`}
                        >
                          Intensity of wants: {getIntensity(data.intensity)}
                        </span>
                        <div className="d-flex gap-2">
                          <ShowLabel label={data.status === 1 ? "Agreed" : data.status === 2 ? "Rejected" : data.status === 3 ? "Partial" : null}
                            status={data.status === 1 ? StatusType.SUCCESS : data.status === 2 ? StatusType.DANGER : data.status === 3 ? StatusType.WARNING : null}
                          />

                          {data.objection &&
                            (
                              <span className="  fs-normal-light text-grey">
                                :  {data.objection}
                              </span>
                            )
                          }
                        </div>


                      </div>
                    </div>
                  ))}
                </div>
              </div>



            </ViewMoreContainer>

            <ViewMoreContainer
              sectionId={EVIDENCES}
              label="Evidences"
              showLoadBtn={false}
              loadBtnStatus={true}
              setLoadBtnStatus={() => { }}
              scrutinyCount={null}
            >

              <div className="px-3 py-4">
                <div className="row row-cols-2 g-5">
                  {caseFileDetail?.synopsis.map((data: SynopsisModel, synopsisIndex: number) => (
                    <div className="mb-3" key={synopsisIndex}>
                      <div className="p-2">

                        <p className="fs-normal-light p-0 m-0">  

                            {data.dateType === DateOption.NOT_APPLICABLE
                              ? "N/A"
                              : data?.dates?.length
                                ? data.dates
                                  .map((date: string | null) =>
                                    data.dateType !== DateOption.ONLY_YEAR ? (
                                      formatDateTime(date, false)
                                    ) : (
                                      date
                                    )
                                  )
                                  .join(data.dateType === DateOption.RANGE_OF_DATES ? " - " : " , ")
                                : null}
                        </p>
                      </div>

                      {data.evidences.map((evidence, evidenceIndex) => (
                        <div key={evidenceIndex}>
                          <span className={`fs-normal-light `}>
                            <AttachFileIcon fontSize="small" />
                            <span
                              className={`${evidence.tempReUpload ? "text-success" : ""
                                } cursor-pointer`}
                              onClick={() => {
                                handleViewFile(evidence)
                              }}
                            >
                              {evidence.actualFileName
                                ? evidence.actualFileName
                                : "- - -"}
                            </span>
                            {evidence.tempReUpload && (
                              <CheckCircleIcon
                                className="ms-2 text-success "
                                fontSize="small"
                              />
                            )}
                          </span>
                          <div className="fs-normal-light text-start p-0 m-0">
                            {evidence.description}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>

            </ViewMoreContainer>

          </div>
        </div>
      </div>

      <MediaLayout
        title={fileToOpen?.actualFileName ?? "Media Preview"}
        open={openFile}
        handleClose={() => {
          setOpenFile(false)
        }}
      >
        <ViewMedia isPdf={isFilePdf} url={fileUrl ?? ""} />
      </MediaLayout>
    </>
  )
}

export default ReferViewCase