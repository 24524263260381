import { CaseFile } from "../../model/fileCase";
import { validateEmail, validatePhoneNumber } from "../validation";

export const validateCaseFile = (caseFile: CaseFile) => {
  let isValid = true;
let errorMessageList=[] as string[];
  const error: any = {};

  if (!caseFile.advocateName) {
    error.advocateName = "Advocate Name is required";
    errorMessageList.push(error.advocateName);
    isValid = false;
  }

  if (!caseFile.advocateMobileNo) {
    error.advocateMobileNo = "Phone number is required";
    errorMessageList.push(error.advocateMobileNo);

    isValid = false;
  } else {
    if (validatePhoneNumber(caseFile.advocateMobileNo)) {
      error.advocateMobileNo = validatePhoneNumber(caseFile.advocateMobileNo);
      errorMessageList.push(error.advocateMobileNo)
      isValid = false;
    }
  }

  if (!caseFile.advocateEmail) {
    error.advocateEmail = "Email is required";
    errorMessageList.push(error.advocateEmail);
    isValid = false;
  } else {
    if (validateEmail(caseFile.advocateEmail)) {
      error.advocateEmail = validateEmail(caseFile.advocateEmail);
      errorMessageList.push(error.advocateEmail)
      isValid = false;
    }
  }

  return { tempCaseFile: { ...caseFile, errors: error }, isValid: isValid,errorMessageList };
};
