export const validateEmail = (value: string) => {
  const INPUT_VALUE = value;
  const MAX_LENGTH = 50;
  const REGX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
  if(INPUT_VALUE === "") {
    return "Field Cannot Be Empty";
  }
  if(INPUT_VALUE.length > MAX_LENGTH) {
    return `Email Characters Cannot Be More Than ${MAX_LENGTH}`;
  }
  if (!REGX.test(INPUT_VALUE)) {
    return "Invalid Email Address";
  }
  
  return "";
};

export const validatePassword = (value: string) => {
  const INPUT_VALUE = value;
  const MIN_LENGTH = 8;
  const MAX_LENGTH = 40;

  if(INPUT_VALUE.includes(' ')){
    return "Password should not contain spaces"
  }
  if(INPUT_VALUE.length < MIN_LENGTH){
    return `Password should contain atleast ${MIN_LENGTH} characters`
  }
  if (INPUT_VALUE.length > MAX_LENGTH) {
    return `Characters Cannot Be More Than ${MAX_LENGTH}`;
  }
  if(!(/[A-Z]/.test(INPUT_VALUE))){
    return "Password should contain atleast one uppercase letter";
  }
  if(!(/[a-z]/.test(INPUT_VALUE))){
    return "Password should contain atleast one lowercase letter";
  }
  if(!(/\d/.test(INPUT_VALUE))){
    return "Password should contain atleast one digit";
  }
  if(!(/[@$!%*?&]/.test(INPUT_VALUE))){
    return "Password should contain atleast one special character";
  }

  return "";
};

export const validatePhoneNumber = (phoneNumber: string) => {
  const phoneRegex = /^[0-9]+(([',. -][0-9])?[0-9]*)*$/;
  const isValidLength = phoneNumber.length === 10;

  if (isValidLength && phoneRegex.test(phoneNumber)) {
    return "";
  } else {
    return "Invalid Mobile number";
  }
};

export const validateName = (value: string) => {
  const INPUT_VALUE = value?.trim();
  const MAX_LENGTH = 40;
  const REGX = /^[A-Za-z./]+(?: [A-Za-z./]+)*$/;

  if (INPUT_VALUE === "") {
    return "Field Cannot Be Empty";
  }
  if (INPUT_VALUE.length > MAX_LENGTH) {
    return `Characters Cannot Be More Than ${MAX_LENGTH}`;
  }
  if (!REGX.test(INPUT_VALUE)) {
    return "Only Alphabets, Periods, and Slashes Are Allowed";
  }

  return "";
};

