// case details
export const METHOD_OF_RESOLUTION = 0;
export const CLAIMANT_DETAILS = 1;
export const RESPONDENT_DETAILS = 2;
export const REPRESENTATION_TYPE = 3;

// case document
export const SYNOPSIS = 4;
export const DETAILED_FACTS = 5;
export const GROUNDS_REASONING = 6;
export const PRAYERS = 7;

// preview case file
export const CASE_FILE_PREVIEW = 8;

//respondent section
export const RESPONDENT_SYNOPSIS = 9;
export const RESPONDENT_STATEMENT_OF_OBJECTION = 10;
export const RESPONDENT_PRAYER = 11;
export const COUNTER_PRAYER = 12;

export const EVIDENCES = 99; //created this for rendering  evidence in ViewMoreContainer

// pages
export const CASE_DETAILS_STATE = 0;
export const CASE_DOCUMENT = 1;
export const PREVIEW_CASE_FILE = 2;
