export enum MeetingStatus {
  PENDING = "PENDING",
  FINALIZED = "FINALIZED",
  END_MEETING = "END_MEETING",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
}

export enum DailyOrderStatus {
  PUBLISHED = "PUBLISHED",
  NOT_PUBLISHED = "NOT_PUBLISHED",
}
