import React, { useEffect, useRef } from 'react';
import { Evidence } from '../../../../model/caseDocument/synopsis';
import { Slider, TextField, Tooltip } from '@mui/material';
import FileUpload from '../../../../components/common/FileUpload';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { store } from '../../../../redux/store';
import { NEW_CASE, DRAFT } from '../../../../constants/case-constants';



interface EvidenceItemProps {
    evidence: Evidence;
    evidenceIndex: number;
    handelEvidenceChange: (key: string, value: any, evidenceIndex: number) => void;
    deleteEvidence: (evidenceIndex: number) => void;
    scrollEnabled?: boolean;
}

const EvidenceItem: React.FC<EvidenceItemProps> = ({
    evidence,
    evidenceIndex,
    handelEvidenceChange,
    deleteEvidence,
    scrollEnabled
}) => {
    const marks = [
        { label: "Low", value: 1 },
        { label: "Medium", value: 2 },
        { label: "High", value: 3 },
    ];

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current && scrollEnabled) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
        }
    }, [evidenceIndex]);


    const textFieldRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (textFieldRef.current && scrollEnabled) {
            textFieldRef.current.focus();
        }
    }, []);
    const {caseFile}=store.getState().case

    return (
        <div className="d-flex flex-column gap-3 border border-1 p-3" ref={ref} key={evidenceIndex}>
            <div className="d-flex justify-content-between align-items-center gap-4 px-4">
                <span className="fs-normal-semibold">{evidence?.docName}</span>
                <div className="w-50 justify-content-center">
                    <div className="text-grey">sensitivity</div>
                    <div className='d-flex gap-4 '>
                        <Tooltip className=" mt-2" title={"sensitive personal information to be scaled as high"}>
                            <InfoOutlinedIcon />
                        </Tooltip>
                        <Slider
                            aria-label="Always visible"
                            step={1}
                            marks={marks}
                            value={evidence.sensitivity as number}
                            min={1}
                            max={3}
                            valueLabelDisplay="off"
                            onChange={(event, value) =>
                                handelEvidenceChange("sensitivity", value, evidenceIndex)
                            }
                        />
                    </div>
                </div>
            </div>
            <FileUpload
                name={evidence?.docName}
                fileName={evidence.actualFileName}
                addFile={() => { }}
                removeFile={() => {
                    deleteEvidence(evidenceIndex);
                }}
                hintMessage=""
                evidence={evidence}
                url={
                    [
                        caseFile.status === NEW_CASE ? DRAFT : caseFile.status,
                        caseFile.uniqueId ?? "",
                        "SYNOPSIS",
                        evidence.uploadedFileName,
                    ].join("/")
                }
            />
            <div className="row px-2">
                <TextField
                    id={evidenceIndex.toString()}
                    error={!!evidence.error}
                    multiline
                    ref={ref}
                    inputRef={textFieldRef}
                    rows={3}
                    label="Description"
                    placeholder="Enter the description !"
                    helperText={evidence.error}
                    value={evidence.description ? evidence.description : ""}
                    onChange={(event) => {
                        handelEvidenceChange("description", event.target.value, evidenceIndex);
                    }}
                />
            </div>
        </div>
    );
};

export default EvidenceItem;
