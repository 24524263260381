import {
  Box,

  IconButton,
  Skeleton,
  SwipeableDrawer,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";

import closeIcon from "../../assets/icon/navigation/close_24px.png";
import NotificationCard from "./notificationCard";
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import { NotificationType } from "../../model/notification/notification";
import { formatDateTime } from "../../util/stringUtils";
import { CASE, FILE_CASE } from "../../routes/routes";
import { useNavigate } from "react-router";
import CustomLoader from "../common/CustomLoader";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationThunk, updateNotificationThunk } from "../../redux/slice/notificationSlice";
import { DRAFT, LEGAL_REVOKE_ACCEPTED, NON_DRAFT } from "../../constants/case-constants";

const NotificationBar = ({
  openNotification,
  toggleNotificationBar,

}: {
  openNotification: boolean;
  toggleNotificationBar: () => void;

}) => {
  const navigate = useNavigate();


  const dispatch: AppDispatch = useDispatch();

  const { notificationCount, notificationList, isLoading } = useSelector(
    (state: RootState) => state.notification
  );

  const onMarkAllAsRead = () => {
    const uniqueIdList = ["ALL"]
    dispatch(updateNotificationThunk(uniqueIdList?.join(',')))
      .unwrap()
      .then(() => {
        dispatch(getNotificationThunk())
        toggleNotificationBar();
      })
      .catch((e) => {
        console.error(e);
      })
  }
  const onCardClicked = (data: NotificationType) => {
    dispatch(updateNotificationThunk(data.uniqueId))
      .unwrap()
      .then(() => dispatch(getNotificationThunk()))
      .catch((e) => console.error(e));

    toggleNotificationBar();

    // navigate(FILE_CASE + `/${status === LEGAL_REVOKE_ACCEPTED ? NON_DRAFT : DRAFT}/` + uniqueId);
    //TODO: handle for legal revoke accepted status

    let casePath = ""
      if(data.status ===LEGAL_REVOKE_ACCEPTED){
      
        casePath = FILE_CASE + `/${ NON_DRAFT }/` + data.caseUniqueId

    }else{
       casePath= data.responseUniqueId
          ? CASE + "/" + data.caseUniqueId + "/" + data.responseUniqueId
          : CASE + "/" + data.caseUniqueId;
    }

    navigate(casePath);
  }


  if (openNotification) {
    return (<SwipeableDrawer
      anchor="right"
      open={openNotification}
      onClose={toggleNotificationBar}
      onOpen={toggleNotificationBar}
      PaperProps={{ style: { borderRadius: "5px 0 0 5px" } }}
    >
      <div className="d-flex gap-3 flex-column  overflow-hidden">
        <div className="w-100 d-flex justify-content-between align-items-center p-2 mx-2">
          <div className="fs-med-lg-semibold d-flex align-items-center gap-3">
            <span>Notifications</span>
            <div className="text-center">
              {notificationList?.length ? (
                <IconButton onClick={notificationCount ? onMarkAllAsRead:()=>{}}
                >
                  <Tooltip title="Mark all read">
                    {notificationCount?
                    <MarkunreadOutlinedIcon fontSize="small" className="text-grey" />
                    :
                    <DraftsOutlinedIcon fontSize="small" className="text-grey" />
                    }

                  </Tooltip>
                </IconButton>
              ) : null}
            </div>
          </div>
          <IconButton className="p-3 " onClick={toggleNotificationBar}>
            <img src={closeIcon} alt="close icon" />
          </IconButton>
        </div>

        {!isLoading ? (
          <Box className="d-flex flex-column p-2 overflow-auto" sx={{ width: 320, gap: '24px' }}>
            {notificationList?.length ? (
              <>
                {notificationList?.map((data: NotificationType) => {
                   return ( 
                   <NotificationCard
                      key={data.uniqueId}
                      imgUrl={data.profilePic}
                      userName={data.fullName}
                      read={data.isViewed===1}
                      content={data.description}
                      time={formatDateTime(data.createdAt)}
                     onClick={()=>{
                       onCardClicked(data)
                     }}
                    />)
               
                  }
                
                
                )}
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                No notification!
              </div>
            )}
          </Box>) :
          (
            <div className="d-flex flex-column gap-2">
              <Skeleton variant="rounded" width={300} height={50} />
              <Skeleton variant="rounded" width={300} height={50} />
              <Skeleton variant="rounded" width={300} height={50} />
              <Skeleton variant="rounded" width={300} height={50} />
              <Skeleton variant="rounded" width={300} height={50} />
              <Skeleton variant="rounded" width={300} height={50} />
              <Skeleton variant="rounded" width={300} height={50} />
              <Skeleton variant="rounded" width={300} height={50} />
              <Skeleton variant="rounded" width={300} height={50} />
              <Skeleton variant="rounded" width={300} height={50} />
              <Skeleton variant="rounded" width={300} height={50} />
              <Skeleton variant="rounded" width={300} height={50} />
              <Skeleton variant="rounded" width={300} height={50} />
              <Skeleton variant="rounded" width={300} height={50} />
            </div>
          )}
      </div>
    </SwipeableDrawer>)
  } else {
    return (
      null
    );
  }
};

export default NotificationBar;
