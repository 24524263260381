import { NON_DRAFT } from "../constants/case-constants";
import { PositionType } from "../model/caseListApiModel";
import AxiosHelper from "./AxiosHelper";

const getCaseDetail = ({
  draftStatus,
  uniqueId,
  queryParams = null,
}: {
  draftStatus: string;
  uniqueId: string;
  queryParams?: any;
}) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: `case/case-detail/${draftStatus}/${uniqueId}`,
      queryParams: queryParams,
    })
      .then((res: any) => {
        res.isSuccess ? resolve(res.data) : reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const saveCaseFile = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({ path: `case/save-case-file`, body })
      .then((res: any) => {
        res.isSuccess ? resolve(res.data) : reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const saveCaseFileResponse = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({ path: `case/save-case-response`, body })
      .then((res: any) => {
        res.isSuccess ? resolve(res.data) : reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const verifyResolutionAgreement = (body: any) => {
  const { uniqueId, ...other } = body;



  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: `case/${uniqueId}/verify-agreement`,
      body: other,
    })
      .then((res: any) => {
        res.isSuccess ? resolve(res.data) : reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const updateResolutionAgreement = (body: any) => {
  const uniqueId = body.agreement.uniqueId;
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({ path: `case/${uniqueId}/upload-agreement`, body:body })
      .then((res: any) => {
        res.isSuccess ? resolve(res.data) : reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const caseList = (DRAFT_STATUS: string, reqParams: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: `case/case-list/${reqParams?.responseStatus ? NON_DRAFT : DRAFT_STATUS}`,
      queryParams: {...reqParams, status: null},
    })
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res.data ? res : false);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const startResolutionPhase = (uniqueId: string) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: "case/startResolutionPhase/" + uniqueId,
    })
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res.data ? res : false);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const getRecommendation = (uniqueId: string) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: "case/" + uniqueId + "/recommendation",
      queryParams: null,
    })
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res.data ? res.data : []);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const sendRecommendation = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "case/" + body.uniqueId + "/recommendation",
      queryParams: null,
      body: { ...body, uniqueId: "" },
    })
      .then((res: any) => {
        if (res.status === 1001) {
          resolve(res);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const getRecommendationHistory = (uniqueId: string) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: "case/" + uniqueId + "/recommendation/history",
      queryParams: null,
    })
      .then((res: any) => {
        res.isSuccess ? resolve(res.data) : reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const getCaseResponse = (body: any) => {
  const {uniqueId,queryParams=null}=body;
  
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: "case/case-response/" + uniqueId,
      queryParams: queryParams,
    })
      .then((res: any) => {
        res.isSuccess ? resolve(res.data) : reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const getCaseCount = (data: PositionType | null) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: "case/case-count",
      queryParams: data,
    })
      .then((res: any) => {
        res.isSuccess ? resolve(res.data) : reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const getNeutralList = (value: string) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: "user/neutral",
      queryParams: {
        name: value,
      },
    })
      .then((res: any) => {
        res.isSuccess
          ? resolve(res.data ? res.data : [])
          : reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const getCaseNeutralHistory = (uniqueId: string) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: "case/case-neutral-history/" + uniqueId,
      queryParams: null,
    })
      .then((res: any) => {
        res.isSuccess
          ? resolve(res.data ? res.data : [])
          : reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const chooseNeutralApi = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "case/choose-neutral",
      queryParams: null,
      body: { ...body },
    })
      .then((res: any) => {
        if (res.status === 1001) {
          resolve(res);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const finalizeNeutralApi = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "case/finalize-neutral",
      queryParams: null,
      body: { ...body },
    })
      .then((res: any) => {
        if (res.status === 1001) {
          resolve(res);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const apiCaseTimeline = (uniqueId: string) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: "case/" + uniqueId + "/time-line",
      queryParams: null,
    })
      .then((res: any) => {
        res.isSuccess ? resolve(res.data) : reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const apiSaveCaseTimeline = (body: any) => {
  const {uniqueId} = body;
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "case/" + uniqueId + "/time-line",
      queryParams: null,
      body: body,
    })
      .then((res: any) => {
        if (res.status === 1001) {
          resolve(res);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const publishTimelineApi = (body: any) => {
  const uniqueId = body;
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "case/" + uniqueId + "/time-line/publish",
      queryParams: null,
      body: {},
    })
      .then((res: any) => {
        if (res.status === 1001) {
          resolve(res);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const apiCaseTimelineEvent = (data: { uniqueId: string ,eventId:string}) => {
  const {eventId,uniqueId}=data;
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: "/case/" + uniqueId + "/time-line/" + eventId + "/responses",
      queryParams: null,
    })
      .then((res: any) => {
        res.isSuccess ? resolve(res.data) : reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const apiSaveCaseTimelineEvent = (data:any) => {
  const { uniqueId, eventUniqueId } = data;
  
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "/case/" + uniqueId + "/time-line/" + eventUniqueId + "/response",
      queryParams: null,
      body: data,
    })
      .then((res: any) => {
        if (res.status === 1001) {
          resolve(res);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const saveTimelineDailyOrder = (data:any) => {
  const { uniqueId, eventUniqueId, ...dataToSend } = data;
  
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path:
        "/case/" + uniqueId + "/time-line/" + eventUniqueId + "/daily-order",
      queryParams: null,
      body: dataToSend,
    })
      .then((res: any) => {
        if (res.status === 1001) {
          resolve(res);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const saveTimelineEventMinutesNote = (data:any) => {
  const { uniqueId, eventUniqueId, ...dataToSend } = data;
  
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path:
        "/case/" + uniqueId + "/time-line/" + eventUniqueId + "/meeting-notes",
      queryParams: null,
      body: dataToSend,
    })
      .then((res: any) => {
        if (res.status === 1001) {
          resolve(res);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const apiMeetingSignature = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "/meeting/signature",
      queryParams: null,
      body: body,
    })
      .then((res: any) => {
        resolve(res);
      })
      .catch((e) => reject(e));
  });

};

const apiCreateMeeting = (body: any) => {

  const {caseId,...eventData}=body

  
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "/case/"+caseId+"/time-line/"+eventData.uniqueId+"/create-meeting",
      queryParams: null,
      body: eventData,
    })
      .then((res: any) => {
        if (res.status === 1001) {
          resolve(res);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const apiDownloadReacordings = (meetingId: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "/meeting/" + meetingId + "/recording",
      queryParams: null,
    })
      .then((res: any) => {
        if (res.status === 1001) {
          resolve(res);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const saveFinalVerdictApi = (data: any) => {
  
  const {uniqueId,dataToSend}=data
  
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "/case/" + uniqueId + "/final-verdict",
      queryParams: null,
      body:dataToSend
    })
      .then((res: any) => {
        if (res.status === 1001) {
          resolve(res);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
// const apiGetMeetingTrascription = (body: any) => {
//   return new Promise((resolve, reject) => {
//     AxiosHelper.httpPost({
//       path: "/meeting/:meetingId/recording",
//       queryParams: null,
//       // body: body,
//     })
//       .then((res: any) => {
//         if (res.status === 1001) {
//           resolve(res);
//         } else reject(res.description);
//       })
//       .catch((e) => reject(e));
//   });
// };

const getNotificationApi = () => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: "/user/notification",
      queryParams: null,
    })
      .then((res: any) => {
        res.isSuccess ? resolve(res.data) : reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const updateNotification = ({ queryParams = null }: { queryParams?: any }) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "/user/notifications/markAsRead",
      queryParams: queryParams,
    })
      .then((res: any) => {
        if (res.status === 1001) {
          resolve(res);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const deleteCase = ( body :any) => {
  const {status,uniqueId}=body
  
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: `/case/${status}/${uniqueId}/delete`,
    })
      .then((res: any) => {
        if (res.status === 1001) {
          resolve(res);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};



const NetworkService = {
  getCaseDetail,
  saveCaseFile,
  caseList,
  getRecommendation,
  sendRecommendation,
  getRecommendationHistory,
  getCaseResponse,
  saveCaseFileResponse,
  getCaseCount,
  apiCaseTimeline,
  apiSaveCaseTimeline,
  getNeutralList,
  chooseNeutralApi,
  finalizeNeutralApi,
  getCaseNeutralHistory,
  startResolutionPhase,
  updateResolutionAgreement,
  verifyResolutionAgreement,

  apiCaseTimelineEvent,
  publishTimelineApi,
  apiSaveCaseTimelineEvent,
  saveTimelineEventMinutesNote,
  saveTimelineDailyOrder,

  apiMeetingSignature,
  apiCreateMeeting,
  apiDownloadReacordings,
  saveFinalVerdictApi,

  //notification
  getNotificationApi,
  updateNotification,

  //delete case
  deleteCase,
};

export default NetworkService;
