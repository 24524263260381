import React, { ChangeEvent, useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box } from "@mui/material";
import Doc22Icon from "../../assets/icon/doc_22x.svg";
import CloseIcon from "@mui/icons-material/Close";
import CustomLoader from "./CustomLoader";
import DialogLayout from "../../Layout/DialogLayout";
import DocFIle from '../../assets/DocFile.svg';
import { Evidence } from "../../model/caseDocument/synopsis";
import { DRAFT, NEW_CASE } from "../../constants/case-constants";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import MediaLayout from "../../Layout/MediaLayout";
import ViewMedia from "../ViewMedia";


/**
 * Props for the FileUpload component.
 */
interface FileUploadProps {
  name?: string; // The name of the file input.
  fileName?: string; // The name of the file currently uploaded.
  addFile: (e?: any, extra?: any) => void; // A function to handle adding a file.
  removeFile?: ((key?: string, extra?: any) => void) | null; // A function to handle removing a file.
  downloadFile?: () => void; // A function to handle downloading the file.
  fileInfo?: any; // Any additional information about the file.
  hintMessage?: string; // A hint message to display when no file is selected.
  loading?: boolean; // Whether the component is in a loading state.
  evidence?:Evidence,
  fileType?: string; // The file type to display
  url: string; // The file type to display
}

const FileUpload: React.FC<FileUploadProps> = ({
  name,
  fileName,
  addFile,
  removeFile,
  downloadFile,
  hintMessage,
  fileInfo,
  loading,
  evidence,
  url,
  fileType
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [openFile, setOpenFile] = useState(false);
  const [isFilePdf, setIsFilePdf] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [fileToOpen, setFileToOpen] = useState<Evidence | null>(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const {caseFile}= useSelector((state: RootState) => state.case);

  const handleViewFile = async (evidence: Evidence) => {
    // const url = [
    //   caseFile.status===NEW_CASE?DRAFT:caseFile.status,
    //   caseFile.uniqueId ?? "",
    //   "SYNOPSIS",
    //   evidence.uploadedFileName,
    // ].join("/");

    setIsFilePdf(url.endsWith(".pdf"));
    setOpenFile(true);
    setFileUrl(url);
    setFileToOpen(evidence);

  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault(); // Prevent default action
    setIsDraggingOver(false);

    if (fileInputRef?.current) {
      fileInputRef.current.value = '';
    }
    
    addFile((e as unknown) as Event, { ...fileInfo, key: name });
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(true);
    e.dataTransfer.dropEffect = "copy";
    // if (fileInputRef?.current) {
    //   fileInputRef.current.value = '';
    // }
    // addFile((e as unknown) as Event, { ...fileInfo, key: name });

  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const handleRemoveFile = () => {
    if (removeFile) removeFile(name, { ...fileInfo, key: name });
  };
  const [openDocDialog, setOpenDocDialog] = useState(false);
  const [docInfo, setDocInfo] = useState<any | null>(null);

  const toggleDocDialog = (fileInfo: any = {}) => {
    if (openDocDialog) {
      setDocInfo(null);
      setOpenDocDialog(false);
    } else {
      setDocInfo(fileInfo);
      setOpenDocDialog(true);
    }
  }

  

  return (
    <>
      <input
        type={"file"}
        name={name}
        accept={fileType ?? ".pdf,.jpg,.png"} // Specify accepted file types if needed
        onChange={(e) => {
          addFile((e as unknown) as Event, { ...fileInfo, key: name })
          if (fileInputRef?.current) {
            fileInputRef.current.value = '';
          }
        }}
        style={{ display: "none" }}
        ref={fileInputRef}
      />

      {!fileName  ? (
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onClick={() => fileInputRef.current?.click()}
        >
          <div
            className={`drop-area ${isDraggingOver ? 'drag-over' : ''}`}
          >
            {!loading ? (
              <>
                <CloudUploadIcon sx={{ color: "grey", fontSize: "28px" }} />
                <span className="fs-sm-lg-med text-grey">
                  Browse or Drag & drop
                </span></>
            ) : <><span className="fst-italic fw-bold">Loading...</span></>}
          </div>

          <span className="fs-normal-light text-danger">{hintMessage}</span>
        </div>
      ) : (
        <div
          className="position-relative d-flex gap-2 align-items-center flex-column py-4 px-5"
          style={{ border: "2px dashed grey", borderRadius: "5px" }}
        >
          <img src={Doc22Icon} height={28} width={28} alt="..." onClick={() => {


            toggleDocDialog({ filename: fileName })
          }} />
          <span className="fs-normal-med text-center text-break cursor-pointer" onClick={()=>{
            handleViewFile(evidence?? {} as Evidence)
          }}>
            {fileName}
          </span>


          {removeFile ? (<div
            className="position-absolute top-0 end-0 p-1 cursor-pointer"
            onClick={handleRemoveFile}
          >
            <CloseIcon />
          </div>) : null
          }

        </div>
      )}

 


<MediaLayout
        title={fileToOpen?.actualFileName ?? "Media Preview"}
        open={openFile}
        handleClose={() => {
          setOpenFile(false)
        }}
      >
        <ViewMedia isPdf={isFilePdf} url={fileUrl ?? ""} />
      </MediaLayout>
    </>
  );
};

export default FileUpload;