import { PersonalDetail } from './../../model/PersonalDetail';
import { RejectStatus } from "../../model/caseDocument/synopsis";
import { all } from 'axios';

const hasChangesAndRejected = (data: any): boolean => {

  if(data?.hasChanges){
    return data?.rejected !== null;
  }else{
    return true
  }
  
  // return data?.hasChanges && data?.rejected !== null; // checks if haschanges is true and rejected value is not null
};

export const checkForRejected = (
  dataToCheck: any,
  isRespondent: boolean
): boolean => {
  let allow: boolean = false;

  const checkChangesAndRejected = (dataArray: any[] = []): boolean => {
    if (Array.isArray(dataArray)) {
      return dataArray.some((data: any) => {
        return hasChangesAndRejected(data) || !data.hasChanges;
      });
    }
    return false;
  };

  if (isRespondent) {
    allow =
      (dataToCheck?.synopsis &&
        checkChangesAndRejected(dataToCheck.synopsis)) ;


        if(allow){
          allow = hasChangesAndRejected(dataToCheck.statementOfObjection);
        }
  } else {

    
    allow =
      checkChangesAndRejected(dataToCheck.claimantList) && //checks if all the list has no "rejected =null , if they has changes"
      dataToCheck.respondentList &&
      checkChangesAndRejected(dataToCheck.respondentList) &&
      dataToCheck.caseFileDetail.synopsis &&
      checkChangesAndRejected(dataToCheck.caseFileDetail.synopsis) &&
      dataToCheck.caseFileDetail.grounds &&
      checkChangesAndRejected(dataToCheck.caseFileDetail.grounds);

      if(allow){
        
          if (allow) {
           
            
            allow = hasChangesAndRejected(dataToCheck.caseFileDetail.detailedFacts);
          }
      }
  }
  
  return allow;
};

export const revortBackRejected = (
  dataToCheck: any,
  isRespondent: boolean
): any => {
  // Create a deep copy of dataToCheck to avoid direct mutations
  let updatedDataToSend = JSON.parse(JSON.stringify(dataToCheck));

  const hasChangesAndRejected = (data: any): boolean => {
    return data.rejected === RejectStatus.REJECTED;
  };

  const checkIfRejected = (dataArray: any[] = []): boolean => {
    if (Array.isArray(dataArray)) {
      return dataArray.some((data: any) => hasChangesAndRejected(data));
    }
    return false;
  };

  const updateArrayIfRejected = (
    dataArray: any[],
    previousArray: any[]
  ): any[] => {
    return dataArray.map((item: any, index: number) => {
      if (hasChangesAndRejected(item)) {
        return {
          ...previousArray[index],
          reason: item.reason,
          rejected: RejectStatus.REJECTED
        };
      }
      return item;
    });
  };

  if (isRespondent) {
    if (checkIfRejected(dataToCheck.caseFileResponseDetail?.synopsis)) {
      updatedDataToSend.caseFileResponseDetail.synopsis = updateArrayIfRejected(
        dataToCheck.caseFileResponseDetail.synopsis,
        dataToCheck.caseFileResponseDetail.previousSynopsis
      );
    }

    if (hasChangesAndRejected(dataToCheck.caseFileResponseDetail.statementOfObjection)) {
      updatedDataToSend.caseFileResponseDetail.statementOfObjection = {
        ...dataToCheck.caseFileResponseDetail.previousStatementOfObjection,
        reason: dataToCheck.caseFileResponseDetail.statementOfObjection?.reason,
        rejected: RejectStatus.REJECTED
      };
    }

    if (dataToCheck.grounds && checkIfRejected(dataToCheck.grounds)) {
      updatedDataToSend.grounds = updateArrayIfRejected(
        dataToCheck.grounds,
        dataToCheck.previousGrounds
      );
    }
  } else {
    if (checkIfRejected(dataToCheck.claimantList)) {
      updatedDataToSend.claimantList = updateArrayIfRejected(
        dataToCheck.claimantList,
        dataToCheck.caseFileDetail.previousClaimants
      );
    }

    if (checkIfRejected(dataToCheck.respondentList)) {
      updatedDataToSend.respondentList = updateArrayIfRejected(
        dataToCheck.respondentList,
        dataToCheck.caseFileDetail.previousRespondents
      );
    }

    if (hasChangesAndRejected(dataToCheck.caseFileDetail.detailedFacts)) {
      updatedDataToSend.caseFileDetail.detailedFacts = {
        ...dataToCheck.caseFileDetail.previousDetailedFacts,
        reason: dataToCheck.caseFileDetail.detailedFacts?.reason,
        rejected: RejectStatus.REJECTED
      };
    }

    if (checkIfRejected(dataToCheck.caseFileDetail.synopsis)) {
      updatedDataToSend.caseFileDetail.synopsis = updateArrayIfRejected(
        dataToCheck.caseFileDetail.synopsis,
        dataToCheck.caseFileDetail.previousSynopsis
      );
    }

    if (dataToCheck.caseFileDetail.grounds && checkIfRejected(dataToCheck.caseFileDetail.grounds)) {
      updatedDataToSend.caseFileDetail.grounds = updateArrayIfRejected(
        dataToCheck.caseFileDetail.grounds,
        dataToCheck.caseFileDetail.previousGrounds
      );
    }
  }

  return updatedDataToSend;
};


