import { Box, TextField, Slider, Tooltip, InputLabel, MenuItem, Select, SelectChangeEvent, FormControl, Button, Step, FormHelperText } from "@mui/material";
import {
  Evidence,
  SynopsisModel,
} from "../../../../model/caseDocument/synopsis";
import { isEditing } from "../../../../util/case/roleUtils";
import FileService from "../../../../network/FileService";
import { ChangeEvent, useRef, useState } from "react";
import { DASHBOARD } from "../../../../routes/routes";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NEW_CASE, DRAFT } from "../../../../constants/case-constants";
import {
  ROLE_ADMIN,
  ROLE_SUPER_ADMIN,
  ROLE_LAW_FIRM_ADMIN,
  ROLE_LAW_FIRM_USER,
} from "../../../../constants/nav-constants";
import { AppDispatch, RootState, store } from "../../../../redux/store";
import FileUpload from "../../../../components/common/FileUpload";
import { addEvidenceDocName, addSynopsisEvidence, deleteSynopsisEvidence, editResponseSynopsis, editResponseSynopsisEvidence, updateSynopsis } from "../../../../redux/slice/caseResponseSlice";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { formatDateTime } from "../../../../util/stringUtils";

import DateRangeIcon from "@mui/icons-material/DateRange";
import CancelIcon from "@mui/icons-material/Cancel";

import { useSnackbar } from "../../../../context/SnackbarContext";
import { AgreementStatus, DateOption } from "../../../../redux/slice/components/initCaseSliceState";
import EvidenceItem from "../../../case/components/Synopsis/EvidenceItem";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { error } from "console";


interface SynopsisRowProps {
  index: number;
  synopsis: SynopsisModel;
}

const SynopsisRow = (props: SynopsisRowProps) => {
  const { synopsis, index } = props
    ;


  const [isUploading, setUploading] = useState<boolean>(false);




  const dispatch: AppDispatch = useDispatch();


  const { caseFile, isRespondent } = useSelector((state: RootState) => state.case);
  // const { synopsis: tempData } = useSelector((state: RootState) => state.caseResponse).caseFileResponseDetail;




  const { roleRid } =
    store.getState().profileDetail.profileDetail.personalDetail;
  const isAdmin = roleRid === ROLE_ADMIN || roleRid === ROLE_SUPER_ADMIN;

  const isLawyer =
    roleRid === ROLE_LAW_FIRM_ADMIN || roleRid === ROLE_LAW_FIRM_USER;

  const { showSnackbar } = useSnackbar();

  const marks = [
    {
      label: "Low",
      value: 1,
    },

    {
      label: "Medium",
      value: 2,
    },

    {
      label: "High",
      value: 3,
    },
  ];


  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const addFile = async (file: File) => {

    try {
      if (caseFile.uniqueId) {
        if (!file) {
          throw Error("File list is null");
          setUploading(false);

        }

        const args: any = [
          caseFile.status === NEW_CASE ? DRAFT : caseFile.status,
          caseFile.uniqueId,
        ];

        const { actualFileName, uploadedFileName, mineType } =
          (await FileService.caseFileUpload(file, args)) as any;

        dispatch(
          addSynopsisEvidence({
            index: index,
            actualFileName: actualFileName,
            uploadedFileName: uploadedFileName,
            mineType: mineType,
          })
        );
        // dispatch(updateSynopsis({ index, key: "evidences" }));
        dispatch(addEvidenceDocName());

        setUploading(false);

      } else {
        setUploading(false);

        navigate(DASHBOARD);
      }
    } catch (error) {
      console.error(error);
      setUploading(false);

    }
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setUploading(true);

    addFile(e.dataTransfer.files[0]);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setUploading(true);
    if (!e.target.files) {
      throw Error("File list is null");

    } else {
      addFile(e.target.files[0]);
    }

  };


  const handelEvidenceChange = (

    key: string,
    value: any,
    evidenceIndex: number
  ) => {
    const synopsysIndex = index;
    dispatch(
      editResponseSynopsisEvidence({ synopsysIndex, evidenceIndex, key, value })
    );



  };

  const deleteEvidence = (i: number) => {
    dispatch(deleteSynopsisEvidence({ index: index, i: i }));
  };

  const [selectedDate, setSelectedDate] = useState(null);

  const currentDate = new Date().toISOString().split('T')[0];

  const currentYear = new Date().getFullYear();


  const handleSelectDate = () => {
    if (!selectedDate) {
      showSnackbar("Please select a date.");
      return;
    }
    if (synopsis?.dates?.length >= 3) {
      showSnackbar("Maximum dates exceeded.");
      return;
    } else if (synopsis.dates.includes(selectedDate)) {
      dispatch(editResponseSynopsis({ index, value: { ...synopsis.errors, dates: "Date is Invalid" }, key: "errors" }));
    } else {
      const dates = [...synopsis.dates, selectedDate];
      dispatch(editResponseSynopsis({ index, value: dates, key: "dates" }));
      setSelectedDate(null);
    }

  };


  const onInputChange = (e: any) => {
    const { name: key, value } = e.target;

    dispatch(editResponseSynopsis({ index, value, key }));
    dispatch(updateSynopsis({ index, key }));
  };
  const onDateChange = (e: any) => {

    const { name: key, value } = e.target;

    if (synopsis.dateType) {
      if(synopsis.dateType!==DateOption.ONLY_YEAR){
        if (e.target.value > currentDate) {
          showSnackbar("Invalid Date");
          return;
        }
      }
      if (synopsis.dateType === DateOption.SINGLE_DATE) {
        const date = [value];
        dispatch(editResponseSynopsis({ index, value: date, key: "dates" }));
      } else if (synopsis.dateType === DateOption.RANGE_OF_DATES) {


        let fromDate = synopsis?.dates[0] ?? "";
        let toDate = synopsis?.dates[1] ?? "";

        if (key === "fromDate") {
          fromDate = value;
        } else {
          toDate = value;

        }
        dispatch(editResponseSynopsis({ index, value: [fromDate, toDate], key: "dates" }));
      } else if (synopsis.dateType === DateOption.MULTIPLE_DATES) {

        setSelectedDate(value);

      } else if (synopsis.dateType === DateOption.ONLY_YEAR) {
        if (value.length < 5) {
          const date = [value];
          dispatch(editResponseSynopsis({ index, value: date, key: "dates" }));
        }
      }
    }

    dispatch(updateSynopsis({ index, key: "dates" }));
  };


  const removeDate = (i: number) => {



    // Make a copy of the dates array
    let newDateList = [...(synopsis?.dates || [])];
    // Remove the item at the specified index
    newDateList.splice(i, 1);


    // Dispatch the action to edit the synopsis with the updated list
    dispatch(editResponseSynopsis({ index, value: [...newDateList], key: "dates" }));
    // dispatch(updateSynopsis({ index, key:"datetime" }));

  }



  const downloadFile = async (evidence: Evidence) => {
    try {
      const res: ArrayBuffer = (await FileService.downloadCaseFile([
        caseFile.status,
        caseFile.uniqueId ?? "",
        evidence.uploadedFileName,
      ])) as ArrayBuffer;
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", evidence.actualFileName);

      document.body.appendChild(link);
      link.click();
    } catch (error) { }
  };



  const handlechange = (event: SelectChangeEvent) => {

    //added to slice
    dispatch(editResponseSynopsis({ index, value: event.target.value, key: "dateType" }));
    //remove existing date
    dispatch(editResponseSynopsis({ index, value: [], key: "dates" }));

    dispatch(editResponseSynopsis({ index, value: { ...synopsis.errors, dateType: "" }, key: "errors" }));

  }

  return (
    <div className=" h-100 ">
      <div className="d-flex gap-4 justify-content-center overflow-auto" >
        <div className="d-flex flex-column justify-content-center" >
          <div className="bg-white h-100  d-flex flex-grow-1 flex-column align-items-center justify-content-center">
            <Box className="d-flex flex-column gap-2 pt-2">
              <div className="d-flex gap-1  flex-wrap align-items-center pb-2" style={{ width: 650 }} >
                  

                <FormControl>
                  <InputLabel id="demo-simple-select-error-label">Date Option</InputLabel>
                  <Select

                    labelId="demo-simple-select-error-label"
                    id="demo-simple-select-error"
                    value={synopsis?.dateType?.toString()}
                    label="Date Option"
                    onChange={handlechange}
                    size="small"
                    sx={{ width: 200 }}
                    error={!!synopsis.errors?.dateType}

                    name="selectedOption"
                    className="p-1"
                  >
                    <MenuItem value={DateOption.SINGLE_DATE}>Date</MenuItem>
                    <MenuItem value={DateOption.MULTIPLE_DATES}>Multiple Dates</MenuItem>
                    <MenuItem value={DateOption.RANGE_OF_DATES}>Range of Dates</MenuItem>
                    <MenuItem value={DateOption.ONLY_YEAR}>Only year</MenuItem>
                    <MenuItem value={DateOption.NOT_APPLICABLE}>NA(Not Applicable)</MenuItem>
                  </Select>
                  <FormHelperText >
                    <span className="text-red">
                    {synopsis.errors?.dateType}
                      </span></FormHelperText>
                </FormControl>
                {synopsis.dateType === DateOption.SINGLE_DATE && (
                  <TextField
                    error={!!synopsis.errors?.dates}
                    type="date"
                    label="Date"
                    variant="outlined"
                    value={synopsis?.dates?.length ? synopsis.dates[0] : null}
                    name="dates"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      max: currentDate,
                      min: '1753-01-01'
                    }}
                    helperText={synopsis.errors?.dates}
                    sx={{ width: 200 }}
                    onChange={onDateChange}
                    size="small"
                  />
                )}
                {synopsis.dateType === DateOption.MULTIPLE_DATES && (
                  <>
                    <TextField
                      error={!!synopsis.errors?.dates}
                      helperText={synopsis.errors?.dates}
                      type="date"
                      label="Custom Date"
                      variant="outlined"
                      name="dates"
                      value={selectedDate ? selectedDate : 'dd-mm-yyyy'}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        max: currentDate,
                        min: '1753-01-01'
                      }}
                      size="small"
                      sx={{ width: 200 }}
                      onChange={onDateChange}
                      InputProps={{
                        endAdornment: (
                          <div className="ms-1 cursor-pointer" onClick={handleSelectDate}>
                            <CheckCircleIcon color="primary" />

                          </div>
                        ),
                      }}
                    />
                    {synopsis.dates?.map((item: any, index: number) => (
                      <div className="d-flex align-items-center border rounded flex-wrap" key={index}>
                        <div className="d-flex gap-2 align-items-center px-1 py-1">
                          <DateRangeIcon sx={{ fontSize: "18px", color: "grey" }} />
                          <span className="fs-normal-med text-grey">{formatDateTime(item)}</span>
                        </div>
                        <div className="d-flex align-items-center">
                          <Button
                            color="error"
                            className="d-flex gap-1 align-items-center border-start"
                            onClick={() => removeDate(index)}
                          >
                            <CancelIcon sx={{ fontSize: "18px" }} />
                          </Button>
                        </div>
                      </div>
                    ))}
                  </>
                )}
                {synopsis.dateType === DateOption.RANGE_OF_DATES && (
                  <>

                    <TextField
                      error={!!synopsis.errors?.dates}
                      type="date"
                      label="From Date"
                      variant="outlined"
                      value={synopsis?.dates?.length ? synopsis.dates[0] : null}
                      name="fromDate"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{

                        max: synopsis?.dates?.[1] || currentDate,
                        min: '1753-01-01'
                      }}
                      // helperText={synopsis.errors?.fromDate}
                      sx={{ width: 200 }}
                      onChange={onDateChange}
                      size="small"
                    />


                    <TextField

                      error={!!synopsis.errors?.dates}
                      type="date"
                      label="To Date"
                      variant="outlined"
                      value={synopsis?.dates?.length > 1 ? synopsis.dates[1] : null}
                      name="toDate"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        min: synopsis?.dates?.[0],
                        max: currentDate,
                      }}
                      // helperText={synopsis.errors?.toDate}
                      sx={{ width: 200 }}
                      onChange={onDateChange}
                      size="small"
                    // inputProps={{ min: synopsis.fromDate }}
                    />{DateOption?.RANGE_OF_DATES?<span className="text-red">
                    {synopsis.errors?.dates}
                  </span>:null}
                    
                  </>

                )}
                {synopsis.dateType === DateOption.ONLY_YEAR && (
                  <>
                    <TextField
                      error={!!synopsis.errors?.dates}
                      type="number"
                      label="Year"
                      variant="outlined"
                      value={synopsis?.dates?.length ? synopsis.dates[0] : null}

                      name="dates"
                      InputLabelProps={{ shrink: true }}
                      helperText={synopsis.errors?.dates}
                      sx={{ width: 200 }}

                      size="small"
                      inputProps={{
                        Step: 1,
                        min: 1900,
                        max: currentYear,
                        mxLength: 4
                      }}
                      onChange={onDateChange}
                    />
                  </>

                )}
               
                <Tooltip title={"Fill the Date of Event"}>

                  <InfoOutlinedIcon />
                </Tooltip>
              </div>


              <TextField
                error={!!synopsis.errors?.eventDetail}
                name="eventDetail"
                label="Events"
                value={synopsis?.eventDetail}
                multiline
                helperText={synopsis.errors?.eventDetail}
                rows={5}
                onChange={onInputChange}
              />

              <input
                type="file"
                accept=".pdf,.jpg,.png" // Specify accepted file types if needed
                onChange={(e) => handleFileInputChange(e)}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              {!isAdmin ?
                (
                  <div className=" d-block  justify-content-center ">
                    <span>Attach additional evidences</span>
                    <div className="d-flex justify-content-center mt-2">
                      <Box
                        className={`dropBox  d-flex w-100    justify-content-center align-items-center  `}
                        height={75}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onClick={() => fileInputRef.current?.click()}

                      >
                        {/* <img src={CloudUploadIcon} alt="file upload" /> */}
                        <span className="ms-2">Browse or Drag & drop</span>
                      </Box>

                    </div>
                    <span className="text-red fs-normal-med">
                      {fileInputRef.current?.files?.length === 0 && synopsis.errors?.evidences}

                    </span>

                    {synopsis.evidences?.length > 0 && (
                      <>
                        <div className="text-start fs-med-slg-medium text-grey mb-2 mt-2">
                          Selected Files
                        </div>
                        {synopsis.evidences?.map((evidence, evidenceIndex) => (
                          // <div
                          //   className="d-flex flex-column gap-3 border border-1 p-3"
                          //   key={evidenceIndex}
                          // >
                          //   <div className="d-flex justify-content-between align-items-center gap-4">
                          //     <span className="fs-normal-semibold">
                          //       {evidence?.docName}
                          //     </span>

                          //     <div className="w-50 justify-content-end me-4">
                          //       <div className="text-grey">sensitivity</div>
                          //       <div className='d-flex gap-4 '>
                          //         <Tooltip className=" mt-2" title={"sensitive personal information to be scaled as high"}>
                          //           <InfoOutlinedIcon />
                          //         </Tooltip>
                          //         <Slider
                          //           aria-label="Always visible"
                          //           // getAriaValueText={valuetext}
                          //           step={1}
                          //           marks={marks}
                          //           value={evidence.sensitivity as number}
                          //           min={1}
                          //           max={3}
                          //           valueLabelDisplay="off"
                          //           onChange={(event, value) =>
                          //             handelEvidenceChange(
                          //               "sensitivity",
                          //               value,
                          //               evidenceIndex
                          //             )
                          //           }
                          //         />
                          //       </div>
                          //     </div>
                          //   </div>
                          //   <FileUpload
                          //     name={evidence?.docName}
                          //     fileName={evidence.actualFileName}
                          //     addFile={() => { }}
                          //     removeFile={() => {
                          //       deleteEvidence(evidenceIndex)
                          //     }
                          //     }
                          //     hintMessage={""}
                          //     // downloadFile={() => downloadFile(evidence)}
                          //     evidence={evidence}
                          //     loading={isUploading}
                          //     url={
                          //       [
                          //         caseFile.status === NEW_CASE ? DRAFT : caseFile.status,
                          //         caseFile.uniqueId ?? "",
                          //         "SYNOPSIS",
                          //         evidence.uploadedFileName,
                          //       ].join("/")
                          //     }
                          //   />
                          //   <div className="row px-2">
                          //     <TextField
                          //       error={!!evidence.error}
                          //       multiline
                          //       rows={3}
                          //       label="Description"
                          //       placeholder="Enter the description !"
                          //       helperText={evidence.error}
                          //       value={evidence.description ? evidence.description : ""}
                          //       onChange={(event) => {
                          //         handelEvidenceChange(
                          //           "description",
                          //           event.target.value,
                          //           evidenceIndex
                          //         );
                          //       }}
                          //     />
                          //   </div>
                          // </div>
                          <EvidenceItem
                            key={evidenceIndex}
                            evidence={evidence}
                            evidenceIndex={evidenceIndex}
                            handelEvidenceChange={handelEvidenceChange}
                            deleteEvidence={deleteEvidence}
                            scrollEnabled={synopsis.evidences.length === evidenceIndex + 1}
                          />
                        ))}
                      </>
                    )}
                  </div>
                ) : null}

            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SynopsisRow;
