import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkService from "../../network/NetworkService";
import { DailyOrderStatus, MeetingStatus } from "../../constants/timeline";
import FileService from "../../network/FileService";

export const caseTimelineThunk = createAsyncThunk(
  "/case-timeline",
  async (_uniqueId: string, thunkApi) =>
    await NetworkService.apiCaseTimeline(_uniqueId)
);

// !always pass sorted events in timeLine use sortEventList hook
export const saveCaseTimelineThunk = createAsyncThunk(  
  "/save-case-timeline",
  async (_body: any, thunkApi) =>
    await NetworkService.apiSaveCaseTimeline(_body)
);
export const publishTimelineThunk = createAsyncThunk(  
  "/publish-case-timeline",
  async (_body: any, thunkApi) =>
    await NetworkService.publishTimelineApi(_body)
);


export const caseTimelineEventThunk = createAsyncThunk(
  "/case-timeline-event",
  async (data:{ uniqueId: string ,eventId:string}, thunkApi) =>
    await NetworkService.apiCaseTimelineEvent(data)
);

export const saveCaseTimelineEventThunk = createAsyncThunk(
  "/save-case-timeline-event",
  async (_body: any, thunkApi) =>
    await NetworkService.apiSaveCaseTimelineEvent(_body)
);

export const getMeetingSignatureThunk = createAsyncThunk(
  "/get-meeting-signature",
  async (_body: any, thunkApi) =>
    await NetworkService.apiMeetingSignature(_body)
);

export const createMeetingThunk = createAsyncThunk(
  "/create-meeting",
  async (_body: any, thunkApi) => await NetworkService.apiCreateMeeting(_body)
);
export const getMeetingReacording = createAsyncThunk(
  "/download-meeting",
  async (_body: any, thunkApi) =>
    await NetworkService.apiDownloadReacordings(_body)
);
export const saveFinalVerdict = createAsyncThunk(
  "/final-verdict",
  async (_body: any, thunkApi) =>
    await NetworkService.saveFinalVerdictApi(_body)
);
export const saveDailyOrderThunk = createAsyncThunk(
  "/daily-orders",
  async (_body: any, thunkApi) =>
    await NetworkService.saveTimelineDailyOrder(_body)
);
export const saveMeetingNotesThunk = createAsyncThunk(
  "/meeting-notes",
  async (_body: any, thunkApi) =>
    await NetworkService.saveTimelineEventMinutesNote(_body)
);


//pass url in arguments
// export const DownloadFinalVerdict = createAsyncThunk(
//   "/get-final-verdict",
//   async (_body: any, thunkApi) =>
//     await FileService.downloadFileApi(_body)
// );
// export const getMeetingTranscription = createAsyncThunk(
//   "/create-meeting",
//   async (_body: any, thunkApi) => await NetworkService.apiCreateMeeting(_body)
// );

export interface LogEntry{
  remark: string | null;
  datetime: string;
  fullName: string;
  type: string;
};

export interface SuggestedDate {
  acceptCount: number;
  datetime: string;
  log: LogEntry[];
  rejectCount?: number; // Optional property since it was missing in the provided data
}
const MinutesDoc = {
  actualFileName: "",
  uploadedFileName: "",
  description: "",
  mimeType: "",
  evidenceType: "ANNEXURE" /* or EXHIBIT */,
  evidenceName: "",
};

export type MinutesDocObjType = typeof MinutesDoc;




export interface CaseTimeline {
  uniqueId: string;
  eventId: number;
  eventName: string;
  eventDetail: string;
  eventFromDatetime: string | null;
  eventToDatetime: string | null;
  suggestedDatetime: string | null;
  finalDatetime: string | null;
  status: any;
  suggestion: SuggestedDate[];
  responseCount: number;
  finalised: boolean;

  meetingId: string | number | null;
  meetingPassword: string | null;
  meetingStartUrl: string | null;
  meetingJoinUrl: string | null;
  skip: boolean;
  leaveUrl: string;

  minutesDocObj: MinutesDocObjType | null;

  dailyOrder: string | null;
  dailyOrderStatus: DailyOrderStatus;

  deletedFiles: string[];

  meetingNotes: string | null;
}

export interface CaseTimelineEvent {
  eventUniqueId: string;
  uniqueId: string;
  remark: string;
  type: "ACCEPT" | "REJECT" | "SUGGEST";
  datetime: string | null;
}

export const caseTimelineSlice = createSlice({
  name: "caseTimeline",
  initialState: {
    caseTimeline: [] as CaseTimeline[],
    uniqueId: "",

    isFinalMeetingDone: false,

    suggestionList: [] as CaseTimelineEvent[],
    saveSuggestionLoader: false,
    fetchSuggestionLoader: false,
    createMeetingLoader: false,

    isLoading: false,

    uploadingMinutes: false,
    savingMeetungNotes: false,

    deletedEventList: [] as string[],

    actionLoader: false,
    errorMessage: "",
  },
  reducers: {
    updateCaseTimeline: (state, action) => {
      const timelines = action.payload as any;
      return {
        ...state,
        caseTimeline: timelines,
      };
    },
    addNewEvent: (state, action) => {
      const index = action.payload as any;
      const eventToAdd = {
        eventName: "New event",
        eventDetail: null,
        eventFromDatetime: null,
        eventId: null,
        eventToDatetime: null,
        finalDatetime: null,
        finalised: false,
        meetingId: null,
        meetingJoinUrl: null,
        meetingPassword: null,
        meetingStartUrl: null,
        minutesDocObj: null,
        meetingNotes: null,
        responseCount: 0,
        status: null,
        suggestedDatetime: null,
        suggestion: [],
      };

      const updatedCaseTimeline = [...state.caseTimeline];

      updatedCaseTimeline.splice(index + 1, 0, eventToAdd as any);

      return {
        ...state,
        caseTimeline: updatedCaseTimeline,
      };
    },
    deleteEvent: (state, action) => {
      const index = action.payload as any;

      const updatedCaseTimeline = [...state.caseTimeline];
      const deletedEventId = updatedCaseTimeline[index].uniqueId;

      if (index >= 0 && index < updatedCaseTimeline.length) {
        updatedCaseTimeline.splice(index, 1);
      }

      return {
        ...state,
        caseTimeline: updatedCaseTimeline,
        deletedEventList: [...state.deletedEventList, deletedEventId],
      };
    },

    updateEvent: (state, action) => {
      const { index, key, value } = action.payload;

      

      state.caseTimeline[index] = {
        ...state.caseTimeline[index],
        [key]: value,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(caseTimelineThunk.pending, (state, action) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(caseTimelineThunk.fulfilled, (state, action) => {
        const { timelines } = action.payload as any;

        const lastTimeLine = timelines[timelines.length - 1];

        state.caseTimeline = timelines;
        state.isFinalMeetingDone =
          lastTimeLine.status === MeetingStatus.END_MEETING;
        state.isLoading = false;
        state.errorMessage = "";
      })
      .addCase(caseTimelineThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.isLoading = false;
      })

      // save api
      .addCase(saveCaseTimelineThunk.pending, (state, action) => {
        state.actionLoader = true;
        state.errorMessage = "";
      })
      .addCase(saveCaseTimelineThunk.fulfilled, (state, action) => {
        state.actionLoader = false;
        state.errorMessage = "";
      })
      .addCase(saveCaseTimelineThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.actionLoader = false;
      })
      .addCase(publishTimelineThunk.pending, (state, action) => {
        state.actionLoader = true;
        state.errorMessage = "";
      })
      .addCase(publishTimelineThunk.fulfilled, (state, action) => {
        state.actionLoader = false;
        state.errorMessage = "";
      })
      .addCase(publishTimelineThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.actionLoader = false;
      })

      // fetch suggestion
      .addCase(caseTimelineEventThunk.pending, (state, action) => {
        state.fetchSuggestionLoader = true;
        state.errorMessage = "";
      })
      .addCase(caseTimelineEventThunk.fulfilled, (state, action) => {
        const { responses } = action.payload as any;
        state.suggestionList = responses;
        state.fetchSuggestionLoader = false;
        state.errorMessage = "";
      })
      .addCase(caseTimelineEventThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.fetchSuggestionLoader = false;
      })

      // save suggestion
      .addCase(saveCaseTimelineEventThunk.pending, (state, action) => {
        state.saveSuggestionLoader = true;
        state.errorMessage = "";
      })
      .addCase(saveCaseTimelineEventThunk.fulfilled, (state, action) => {
        state.saveSuggestionLoader = false;
        state.errorMessage = "";
      })
      .addCase(saveCaseTimelineEventThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.saveSuggestionLoader = false;
      })

      // create meeting
      .addCase(createMeetingThunk.pending, (state, action) => {
        state.createMeetingLoader = true;
        state.errorMessage = "";
      })
      .addCase(createMeetingThunk.fulfilled, (state, action) => {
        state.createMeetingLoader = false;
        state.errorMessage = "";
      })
      .addCase(createMeetingThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.createMeetingLoader = false;
      })

      // download meeting
      .addCase(getMeetingReacording.pending, (state, action) => {
        state.createMeetingLoader = true;
        state.errorMessage = "";
      })
      .addCase(getMeetingReacording.fulfilled, (state, action) => {
        state.createMeetingLoader = false;
        state.errorMessage = "";
      })
      .addCase(getMeetingReacording.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.createMeetingLoader = false;
      })
      // Daily order upload
      .addCase(saveDailyOrderThunk.pending, (state, action) => {
        state.uploadingMinutes = true;
        state.errorMessage = "";
      })
      .addCase(saveDailyOrderThunk.fulfilled, (state, action) => {
        state.uploadingMinutes = false;
        state.errorMessage = "";
      })
      .addCase(saveDailyOrderThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.uploadingMinutes = false;
      })
      .addCase(saveMeetingNotesThunk.pending, (state, action) => {
        state.savingMeetungNotes = true;
        state.errorMessage = "";
      })
      .addCase(saveMeetingNotesThunk.fulfilled, (state, action) => {
        state.savingMeetungNotes = false;
        state.errorMessage = "";
      })
      .addCase(saveMeetingNotesThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.savingMeetungNotes = false;
      });
  },
});

export const { updateCaseTimeline, addNewEvent, deleteEvent, updateEvent } =
  caseTimelineSlice.actions;
